import {React, useEffect, useState, useCallback} from "react";
import {DefaultLayout} from "../../components/layouts";
import {useModel, useModels, useQuery} from "../../hooks"
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Upload} from 'antd'
import {
  EditOutlined,
  FolderOpenOutlined,
  PlusCircleOutlined,
  UploadOutlined
} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import StatusResponse from "../../services/statusResponse";
import {ViewLoading} from "../../components";
import locale from "antd/lib/date-picker/locale/es_ES";
import moment from "moment";
import {Respuestas} from "../../utilities/Respuesta";

export const DocumentoDetalle = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const q = useQuery()
  const id = q.get("id")
  const editing = !!id
  const [saveLoading, setSaveLoading] = useState(false)
  const [subirArchivo, setSubirArchivo] = useState(false)
  const breadcrumb = [
    {
      name: "Documentos",
      to: "/documentacion/documentos",
      icon: <FolderOpenOutlined/>
    },
    {
      name: editing ? "Editar" : "Agregar",
      to: editing ?
        `/documentacion/documentos/detalle?id=${id}` :
        `/documentacion/documentos/nueva`,
      icon: editing ? <EditOutlined/> : <PlusCircleOutlined/>
    }
  ];
  const {
    model,
    modelLoading,
  } = useModel({
    name: "documento-publico",
    id: id,
  })

  const [
    categorias,
    categoriasLoading,
  ] = useModels({
    name: "documento-categoria",
    ordenar: "titulo-asc",
    limite: -1,
  })

  const setFormData = useCallback((data) => {
    const {
      idCategoria,
      titulo,
      ruta,
      fechaSubida,
    } = data

    form.setFieldsValue({
      idCategoria,
      titulo,
      ruta,
      fechaSubida: moment(fechaSubida),
      archivo: [
        {
          uid: ruta,
          name: ruta,
          status: "done",
          url: ruta,
        },
      ]
    })
  }, [form])

  useEffect(() => {
    let mounted = true
    if (mounted && editing && model) {
      setFormData(model)
    }
    return () => mounted = false
  }, [editing, model, setFormData]);

  const onFinsih = async values => {
    const {titulo, idCategoria, fechaSubida, archivo} = values
    let _body = {
      titulo,
      idCategoria,
      fechaSubida,
    }
    let ruta = null
    if (editing) {
      _body.id = id
    }
    const formData = new FormData()
    if (archivo?.length) {
      formData.append("archivo", archivo[0]?.originFileObj)
    }

    if (editing) {
      _body.id = id
    }

    try {
      setSaveLoading(true)

      if (subirArchivo) {
        const res = await StatusResponse.postFormData("transparencia-archivo", formData)
        if (res?.status === 400 && res?.errores === null) {
          Modal.error({
            title: res?.mensaje
          })
        } else if (res?.status === 200) {
          if (res?.detalle?.ruta) {
            ruta = res?.detalle?.ruta
            _body.ruta = ruta
          }
        }
      }

      let nombreArchivo = archivo[0]?.name.split(".")
      _body.extension = nombreArchivo[nombreArchivo.length - 1]
      _body.mimeType = archivo[0]?.type;

      const res2 = await StatusResponse.post("documento-publico", _body)
      if (Respuestas(res2))
        history.push("/documentacion/documentos")

    } catch (error) {
      console.log(error)
    } finally {
      setSaveLoading(false)
    }
  };

  if (modelLoading) return <ViewLoading/>

  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title={editing ?
        `Editando Documento: ${model?.titulo}` :
        "Nuevo Documento"}
      children={
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinsih}
          initialValues={{
            fechaSubida: moment(),
          }}
        >
          <Row gutter={[16, 0]}>
            <Col sm={12} md={8}>
              <Form.Item
                style={{marginBottom: '0px'}}
                required
                name="titulo"
                label="Título"
                hasFeedback
                rules={[{required: true, message: 'Por favor ingrese el título del documento'}]}
              >
                <Input
                  maxLength={255}
                  placeholder="Escribe el título del documento"
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={8}>
              <Form.Item
                style={{marginBottom: '0px'}}
                required
                name="idCategoria"
                label="Categoría"
                hasFeedback
              >
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  listItemHeight={10}
                  listHeight={250}
                  loading={categoriasLoading}
                  options={categorias ? categorias.map((m, i) => ({
                    label: `${m.titulo}`,
                    value: m.id,
                    key: i,
                    item: m
                  })) : []}
                  placeholder="Selecciona una categoría"
                  rules={[{required: true, message: 'Por favor seleccione una categoría'}]}
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={8}>
              <Form.Item
                label="Fecha"
                name="fechaSubida"
                style={{marginBottom: '0px'}}
              >
                <DatePicker
                  style={{width: '100%'}}
                  showTime={{format: 'HH:mm'}}
                  format="DD/MM/YYYY HH:mm"
                  placeholder="Selecciona la fecha"
                  locale={locale}
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={8}>
              <Form.Item
                style={{marginBottom: '0px'}}
                required
                name="archivo"
                label="Archivo"
                hasFeedback
                rules={[{required: !editing ? true : false, message: 'Por favor seleccione un archivo'}]}
                valuePropName="fileList"
                getValueFromEvent={(e) => {
                  if (Array.isArray(e)) {
                    return e
                  }
                  return e && e.fileList
                }
                }
                onChange={(_) => setSubirArchivo(true)}
              >
                <Upload
                  style={{width: '100%'}}
                  beforeUpload={() => false}
                  showUploadList={true}
                  onChange={(info) => {
                    if (info.fileList.length > 1) {
                      info.fileList.shift()
                    }
                    form.setFieldsValue({
                      archivo: info.fileList
                    })
                  }}
                >
                  <Button icon={<UploadOutlined/>}>
                    Subir archivo
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]} style={{marginTop: '10px'}}>
            <Col sm={24}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  loading={saveLoading}
                  type="primary"
                >
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      }
    />
  );
};