import React, { Children } from "react";
import {
  Button,
  Descriptions,
  Form,
  Table,
  Tooltip,
  Typography,
  Modal,
  Upload,
  Row,
  Col,
} from "antd";
import {
  FolderOpenOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
  LockOutlined,
  FolderAddOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { DropdownOverlayButton, Uploader } from "../../components";
import SimpleTableLayout from "../../components/layouts/SimpleTableLayout";
import { useModels } from "../../hooks";
import { useHistory, Link } from "react-router-dom";
import StatusResponse from "../../services/statusResponse";
import TablaComponent from "../../components/TablaComponent";

const viewTitle = "Sujetos Obligados";
const searchPlaceholder = "Buscar por título, descripción, etc.";
const emptyText = "No hay Sujetos Obligados registrados...";

const breadcrumbItems = [
  {
    name: "Sujetos Obligados",
    to: "sujetos-obligados",
    icon: <FolderOpenOutlined />,
  },
];

const { Paragraph } = Typography;

export const SujetosObligadosLista = () => {
  const history = useHistory();
  const [pageSize, setPageSize] = React.useState(10);
  const [searchLoading, setSearchLoading] = React.useState(false);
  const [request, setRequest] = React.useState([]);
  const [row, setRow] = React.useState(null);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalArchivoVisible, setModalArchivoVisible] = React.useState(false);

  const [referencias, setReferencias] = React.useState([]);
  const [listaArchivos, setListaArchivos] = React.useState([]);

  const [search, setSearch] = React.useState("");
  let tablaRef = React.useRef(null);

  const [form] = Form.useForm();

  const requestParams = React.useMemo(
    () => ({
      name: "sujeto-obligado",
      ordenar: "id",
      limite: pageSize,
      expand: "ente",
    }),
    [pageSize]
  );

  const buttonData = {
    text: "Nuevo Sujeto Obligado",
    icon: <PlusCircleOutlined />,
    to: () => history.push("/sujetos-obligados/nuevo"),
  };

  const [
    models,
    modelsLoading,
    // eslint-disable-next-line no-unused-vars
    modelsError,
    modelsPage,
    refreshModels,
  ] = useModels(request);

  React.useEffect(() => {
    setRequest(requestParams);
    return () => setRequest({});
  }, [requestParams]);

  const sinDefinir = <i>-Sin definir-</i>;

  const renderSimple = (prop) => prop || sinDefinir;


  const columns = [
    {
      title: "Acciones",
      key: "operation",
      ellipsis: true,
      width: 125,
      render: (_, row) => (
        <DropdownOverlayButton
          options={[
            {
              name: "Ver detalle",
              icon: <EyeOutlined />,
              onClick: () => modalInfo(row),
            },
            {
              name: "Editar información",
              icon: <EditOutlined />,
              onClick: () =>
                history.push(`sujetos-obligados/detalle?id=${row?.id}`),
              styleProps: {
                color: "#4495bb",
              },
            },
            {
              name: "Eliminar",
              icon: <LockOutlined />,
              onClick: () => modalEliminar(row),
              styleProps: {
                color: "#f5222d",
              },
            },
            {
              name: "Agregar documentos",
              icon: <FolderAddOutlined />,
              onClick: () => modalArchivo(row),
              styleProps: {
                color: "#00736c",
              },
            },
          ]}
        />
      ),
    },
    {
      title: "Tipo",
      key: "idAmbito",
      ellipsis: true,
      width: 200,
      render: (_, record) => (
        <Link to={`sujetos-obligados/detalle?id=${record?.id}`} style={{color:"black"}} >
          {record?.ente?.ambito || sinDefinir}
        </Link>
      )
    },
    {
      title: "Sujeto Obligado",
      key: "ente",
      ellipsis: true,
      width: 350,
      render: (_, record) => (
        <Link to={`sujetos-obligados/detalle?id=${record?.id}`} style={{color:"black"}} >
          {record?.ente?.titulo || sinDefinir}
        </Link>
      )
    },
    {
      title: "Enlace",
      dataIndex: "nombre",
      key: "nombre",
      ellipsis: true,
      width: 350,
      render: (_, record) => (
        <Link to={`sujetos-obligados/detalle?id=${record?.id}`} style={{color:"black"}} >
          {renderSimple(record?.nombre)}
        </Link>
      ) 
      
    },
    {
      title: "Dirección",
      key: "direccion",
      render: (_, record) => (
        <Link to={`sujetos-obligados/detalle?id=${record?.id}`} style={{color:"black"}} >
          {renderSimple(record?.direccion)}
        </Link>
      )
    },
  ];

  const ContentModal = ({ row }) => {
    return (
      <div>
        <Descriptions
          bordered
          column={1}
          size="small"
          title="Información del Sujeto Obligado"
        >
          <Descriptions.Item label="ID" labelStyle={{ fontWeight: "bold" }}>
            {" "}
            {row?.id || sinDefinir}{" "}
          </Descriptions.Item>
          <Descriptions.Item label="Ámbito">
            {" "}
            {row?.ente?.ambito || sinDefinir}{" "}
          </Descriptions.Item>
          <Descriptions.Item
            label="Sujeto Obligado"
            labelStyle={{ fontWeight: "bold" }}
          >
            {" "}
            {row?.ente?.titulo || sinDefinir}{" "}
          </Descriptions.Item>
          <Descriptions.Item label="Nombre">
            {" "}
            {row?.nombre || sinDefinir}{" "}
          </Descriptions.Item>
          <Descriptions.Item label="Dirección">
            {" "}
            {row?.direccion || sinDefinir}{" "}
          </Descriptions.Item>
          <Descriptions.Item label="Correo Electrónico">
            {" "}
            {row?.correo || sinDefinir}{" "}
          </Descriptions.Item>
          <Descriptions.Item label="Teléfono">
            {" "}
            {row?.telefono || sinDefinir}{" "}
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  };

  const ContentModalArchivo = ({ row }) => {
    
    return (
      <>
        <Row>
          <Col span={24}>
            <Descriptions
              bordered
              column={1}
              size="small"
              title={`Agregar Archivos - ${row.nombre}`}
              layout="vertical"
              style={{ width: "100%" }}
            >
              <Descriptions.Item
                label="Archivos"
                labelStyle={{ fontWeight: "bold" }}
              >
                <Uploader
                  endPoint={`sujetos-obligados-archivo?id=${row.id}`}
                  listaArchivos={listaArchivos}
                  setListaArchivos={setListaArchivos}
                  setReferencias={setReferencias}
                />
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <br />
        <Row justify="space-between">
          <Col>
            <Button
              key="back"
              onClick={() => setModalArchivoVisible(false)}
              type="primary"
              style={{ marginRight: "10px" }}
            >
              Volver
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  const modalInfo = async (v) => {
    setRow(v);
    setModalVisible(true);
  };

  const encontrarArchivos = (v) => {
    
    let archivos = [];

    for (let i = 0, l = v?.length; i < l; i++) {
      let item = v[i];
      item.url = item.ruta;
      item.name = item.nombre;

      archivos.push(item);
    }

    console.log(archivos)
    return archivos;
  }

  const modalArchivo = async (v) => {
    let lista = await encontrarArchivos(v?.archivoSujetoObligados)
    setRow(v);
    console.log(v);
    setModalArchivoVisible(true);
    setListaArchivos(lista);
    setReferencias(v?.referencias);
  };

  const onSearch = React.useCallback(async (v) => {
    setSearchLoading(true);
    setSearch(v);
    setTimeout(() => setSearchLoading(false), 1500);
  }, []);

  const modalEliminar = (valor) => {
    console.log(valor);
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{ color: "red" }} />,
      content: `¿Desea eliminar el "${valor?.idAmbito} - ${valor?.nombre}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: { danger: true },
      closable: true,
      onOk: async () => {
        const res = await StatusResponse.delete("sujeto-obligado/eliminar", {
          id: valor.id,
        });
        return new Promise((resolve, reject) => {
          try {
            if (valor.id > 0) {
              if (res && res.status === 200) {
                refreshModels();
                resolve();
              } else if (res?.status === 400) {
                Modal.error({
                  title: "Error",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        });
      },
    });
  };

  return (
    <SimpleTableLayout
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      title={viewTitle}
      searchPlaceholder={searchPlaceholder}
      searchLoading={searchLoading}
      onSearchClicked={onSearch}
      emptyText={emptyText}
    >
      <TablaComponent
        columns={columns}
        nameURL={"sujeto-obligado"}
        expand={"ente,archivoSujetoObligados"}
        innerRef={tablaRef}
        extraParams={{ q: search }}
      />
      <Modal
        onCancel={() => setModalVisible(false)}
        cancelButtonProps={{ type: "primary" }}
        cancelText="Cerrar"
        onOk={() => history.push(`sujetos-obligados/detalle?id=${row?.id}`)}
        okButtonProps={{ type: "danger" }}
        okText="Editar"
        open={modalVisible}
        width={"50%"}
      >
        <ContentModal row={row} />
      </Modal>
      <Modal
        open={modalArchivoVisible}
        onCancel={() => setModalArchivoVisible(false)}
        footer={false}
      >
        <ContentModalArchivo row={row} />
      </Modal>
    </SimpleTableLayout>
  );
};
