import {React} from "react";
import {DefaultLayout} from "../../components/layouts";
import {useModel, useQuery} from "../../hooks";
import {
  EditOutlined,
  FolderOpenOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {Formulario} from "./form/Formulario";

export const Detalle = ({endPoint, expand, url, orden, idModelo, media, viewTitle}) => {
  const q = useQuery();
  const id = q.get("id");
  const editing = !!id;
  const breadcrumb = [
    {
      name: viewTitle,
      to: url,
      icon: <FolderOpenOutlined/>,
    },
    {
      name: editing ? "Editar" : "Agregar",
      to: editing
        ? `/${url}/detalle?id=${id}`
        : `/${url}/detalle`,
      icon: editing ? <EditOutlined/> : <PlusCircleOutlined/>,
    },
  ];
  const {
    model,
    modelLoading
  } = useModel({
    name: endPoint,
    id: id,
    expand: expand
  });

  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title={
        editing
          ? `${viewTitle}: ${model?.nombre}`
          : `Nuevo ${viewTitle}`
      }
      children={
        <Formulario
          id={id}
          url={url}
          model={model}
          idModelo={idModelo}
          expand={expand}
          endPoint={endPoint}
          editando={editing}
          media={media}
        />
      }
    />
  );
};
