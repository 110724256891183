import React, {useEffect, useMemo, useState} from "react";
import { DefaultLayout} from '../../components/layouts';
import {useAuth, useModels} from "../../hooks";
import {Row, Col, Card, Statistic} from "antd";
import Title from "antd/lib/typography/Title";
import {CheckCircleOutlined, FileTextOutlined, StopOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";

const Home = () => {
  const history = useHistory();
  const [organoControl, setOrganoControl] = useState(0);
  const [organoControlVisto, setOrganoControlVisto] = useState(0);
  const [recursoRevision, setRecursoRevision] = useState(0);
  const [recursoRevisionVisto, setRecursoRevisionVisto] = useState(0);
  const [solicitudInformacion, setSolicitudInformacion] = useState(0);
  const [solicitudInformacionVisto, setSolicitudInformacionVisto] = useState(0);
  const [buzon, setBuzon] = useState(0);
  const [buzonVisto, setBuzonVisto] = useState(0);
  const [denuncia, setDenuncia] = useState(0);
  const [denunciaVisto, setDenunciaVisto] = useState(0);

  const [
    correspondencia,
    correspondenciaLoading
  ] = useModels({
    name: 'dashboard/correspondencia',
  });

  const enviar = (url, algo) => {
    console.log(34)
    history.push(url);
  };

  useEffect(()=>{
    console.log(correspondencia);
    let _OrganoControl = correspondencia[0]?.OrganoControl;
    let _OrganoControlVisto = correspondencia[0]?.OrganoControlVisto;
    let _RecursoRevision = correspondencia[0]?.RecursoRevision;
    let _RecursoRevisionVisto = correspondencia[0]?.RecursoRevisionVisto;
    let _SolicitudInformacion = correspondencia[0]?.SolicitudInformacion;
    let _SolicitudInformacionVisto = correspondencia[0]?.SolicitudInformacionVisto;
    let _buzon = correspondencia[0]?.buzon;
    let _buzonVisto = correspondencia[0]?.buzonVisto;
    let _denuncia = correspondencia[0]?.denuncia;
    let _denunciaVisto = correspondencia[0]?.denunciaVisto;

    setOrganoControl(_OrganoControl);
    setOrganoControlVisto(_OrganoControlVisto);
    setRecursoRevision(_RecursoRevision);
    setRecursoRevisionVisto(_RecursoRevisionVisto);
    setSolicitudInformacion(_SolicitudInformacion);
    setSolicitudInformacionVisto(_SolicitudInformacionVisto);
    setBuzon(_buzon);
    setBuzonVisto(_buzonVisto);
    setDenuncia(_denuncia);
    setDenunciaVisto(_denunciaVisto);

  }, [correspondencia]);
      
  return (
    <DefaultLayout
      title={"Dashboard"}
    >
      <Row>
        <Col span={24}>
          <Title level={4}>Mensajería</Title>
        </Col>
      </Row>
      <Row gutter={[10, 10]}>

        <Col span={8}>
          <Card
            onClick={() => enviar("/mensajeria/solicitud-informacion", 1)}
          >
            <Statistic
              title="Solicitud de información"
              value={(solicitudInformacionVisto) + "/" + solicitudInformacion}
              valueStyle={{
                color: "#3f51b5",
              }}
              prefix={<FileTextOutlined/>}
            />
          </Card>
        </Col>

        <Col span={8}>
          <Card
            onClick={() => enviar("/mensajeria/organo-control", 1)}
          >
            <Statistic
              title="Buzón de quejas"
              value={(organoControlVisto) + "/" + organoControl}
              valueStyle={{
                color: "#3f51b5",
              }}
              prefix={<FileTextOutlined/>}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            onClick={() => enviar("/mensajeria/recurso-revision", 1)}
          >
            <Statistic
              title="Recursos de Revisión"
              value={(recursoRevisionVisto) + "/" + recursoRevision}
              valueStyle={{
                color: "#3f51b5",
              }}
              prefix={<FileTextOutlined/>}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card
            onClick={() => enviar("/mensajeria/denuncia", 1)}
          >
            <Statistic
              title="Denuncias"
              value={(denunciaVisto) + "/" + denuncia}
              valueStyle={{
                color: "#3f51b5",
              }}
              prefix={<FileTextOutlined/>}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card
            onClick={() => enviar("/mensajeria/buzon", 1)}
          >
            <Statistic
              title="Buzon"
              value={(buzonVisto) + "/" + buzon}
              valueStyle={{
                color: "#3f51b5",
              }}
              prefix={<FileTextOutlined/>}
            />
          </Card>
        </Col>
      </Row>
    </DefaultLayout>
  );
};

export default Home;