import {React} from "react";
import {DefaultLayout} from "../../components/layouts";
import {useModel, useQuery} from "../../hooks"
import {Button, Descriptions, Upload} from 'antd'
import {
  AuditOutlined,
  EyeOutlined, FilePdfOutlined,
} from "@ant-design/icons";
import {ViewLoading} from "../../components";

export const BuzonMunicipioDetalle = () => {
  const q = useQuery()
  const id = q.get("id")
  const editing = !!id
  const breadcrumb = [
    {
      name: "Buzón",
      to: "/buzon-municipios",
      icon: <AuditOutlined/>
    },
    {
      name: editing && "Mensaje",
      to: editing && `/buzon-municipios/detalle?id=${id}`,
      icon: editing && <EyeOutlined/>
    }
  ];
  const {
    model,
    modelLoading,
  } = useModel({
    name: "buzon-municipio",
    id: id,
  })

  if (modelLoading) return <ViewLoading/>

  console.log(model)

  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title={editing && `Mensaje de ${model?.nombre}`}
      children={<>


        <Descriptions
          bordered
          column={{xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1}}
          size="small"
        >
          <Descriptions.Item label="Teléfono:" span={2}>{model?.telefono}</Descriptions.Item>
          <Descriptions.Item label="Correo Electrónico:" span={2}> {model?.email}</Descriptions.Item>
          <Descriptions.Item label="Descripción" span={5}>{model?.descripcion}</Descriptions.Item>

        </Descriptions>
      </>
      }
    />
  );
};