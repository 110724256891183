import {
  AccountBookOutlined,
  AppstoreOutlined,
  AuditOutlined,
  BlockOutlined,
  BookOutlined,
  ContainerOutlined,
  ExceptionOutlined,
  FileSearchOutlined,
  FileSyncOutlined,
  FileTextOutlined,
  FileUnknownOutlined,
  FileWordOutlined,
  FolderOpenOutlined,
  GoldOutlined,
  HomeOutlined,
  IssuesCloseOutlined,
  LogoutOutlined,
  MailOutlined,
  NotificationOutlined,
  ReconciliationOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React from "react";
import {Redirect} from "react-router-dom";
import {useAuth} from "../hooks";

import Home from "../views/home/Home";
import {NotAuthorized, NotFound} from "../views/shared";

import {
  ArticuloDetalle,
  ArticuloLista,
} from "../views/administracion/articulos";
import {
  CategoriaDetalle,
  CategoriaLista,
} from "../views/administracion/categorias";
import {EnteDetalle, EnteLista} from "../views/administracion/ente";
import {
  Permisos,
  PermisosForm,
  PermisosModuloForm,
} from "../views/administracion/permisos";
import {
  PortalCategoriaDetalle,
  PortalCategoriaLista,
} from "../views/administracion/portal-administracion-categoria";
import {
  PortalSubCategoriaDetalle,
  PortalSubCategoriaLista,
} from "../views/administracion/portal-administracion-sub-categoria";
import {UsuarioDetalle, UsuarioLista} from "../views/administracion/usuarios";
import {BuzonDetalle, BuzonLista} from "../views/buzon";
import {DenunciaDetalle, DenunciaLista} from "../views/denuncia";
import {DocumentoDetalle, DocumentoLista} from "../views/documentos";
import {
  DocumentoAdministrativaDetalle,
  DocumentoAdministrativaLista,
} from "../views/documentos-administrativa";
import {
  OrganoControlDetalle,
  OrganoControlLista,
} from "../views/organo-control-informacion";
import {PublicacionDetalle, PublicacionLista} from "../views/publicaciones";
import {
  RecursoRevisionDetalle,
  RecursoRevisionLista,
} from "../views/recurso-revision";
import {
  RevisionObligacionesDetalle,
  RevisionObligacionesLista,
} from "../views/revision-obligacion";
import {SeccionDetalle, SeccionLista} from "../views/secciones";
import {
  SolicitudInformacionDetalle,
  SolicitudInformacionLista,
} from "../views/solicitud-informacion";
import {
  SujetosObligadosDetalle,
  SujetosObligadosLista,
} from "../views/sujetos-obligados";
import {
  TransparenciaDetalle,
  TransparenciaLista,
} from "../views/transparencia";
import {PonenciaDetalle, Ponencias} from "../views/administracion/ponencias";
import ProfileView from "../views/profile/ProfileView";
import {BuzonMunicipioLista} from "../views/buzon-municipio/BuzonMunicipioLista";
import {BuzonMunicipioDetalle} from "../views/buzon-municipio/BuzonMunicipioDetalle";
import {Directorio, DirectorioDetalle} from "../views/directorio";
import {PublicacionTipo, PublicacionTipoDetalle} from "../views/administracion/publicacion-tipo";
import {PublicacionSubTipo, PublicacionSubTipoDetalle} from "../views/administracion/publicacion-sub-tipo";
import {PublicacionNota, PublicacionNotaDetalle} from "../views/publicacion-nota";

const singOutRoute = () => {
  return "Cargando...";
};

const sharedRoutes = [
  {
    path: "/no-autorizado",
    component: NotAuthorized,
  },
  {
    path: "/salir",
    icon: LogoutOutlined,
    component: singOutRoute,
  },
  {
    path: "*",
    component: NotFound,
  },
];

const TienePermiso = () => {
  const {user} = useAuth();
  const esAdmin = user?.rol === "admin" || user?.rol === "transparencia";
  if (esAdmin) return <Home/>;
  else return <Redirect to="/documentacion/transparencia"/>;
};

const dashboardRoutes = [
  {
    layout: "dashboard",
    path: "/",
    name: "Inicio",
    icon: <HomeOutlined/>,
    sidebar: "single",
    component: TienePermiso,
  },
  {
    layout: "dashboard",
    path: "/perfil",
    name: "Perfil",
    icon: <UserOutlined/>,
    sidebar: "none",
    component: ProfileView,
  },
  {
    layout: "dashboard",
    path: "/sujetos-obligados",
    name: "Sujetos Obligados",
    icon: <TeamOutlined/>,
    sidebar: "single",
    ver: 4,
    routes: [
      {
        path: "/",
        component: SujetosObligadosLista,
      },
      {
        path: "/nuevo",
        component: SujetosObligadosDetalle,
      },
      {
        path: "/detalle",
        component: SujetosObligadosDetalle,
      },
    ],
  },
  {
    layout: "dashboard",
    path: "/directorio",
    name: "Directorio ISTAI",
    icon: <TeamOutlined/>,
    sidebar: "single",
    ver: 30,
    routes: [
      {
        path: "/",
        component: Directorio,
      },
      {
        path: "/detalle",
        component: DirectorioDetalle,
      },
    ],
  },
  {
    layout: "dashboard",
    path: "/mensajeria",
    name: "Mensajería",
    icon: <ContainerOutlined/>,
    sidebar: "collapse",
    ver: 1,
    routes: [
      {
        path: "/solicitud-informacion",
        name: "Solicitud de Información",
        icon: <ExceptionOutlined/>,
        sidebar: "single",
        ver: 5,
        routes: [
          {
            path: "/",
            component: SolicitudInformacionLista,
          },
          {
            path: "/detalle",
            component: SolicitudInformacionDetalle,
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/buzon-quejas",
        //name: "Órgano Interno de Control",
        name: "Buzón de quejas",
        icon: <IssuesCloseOutlined/>,
        sidebar: "single",
        ver: 6,
        routes: [
          {
            path: "/",
            component: OrganoControlLista,
          },
          {
            path: "/detalle",
            component: OrganoControlDetalle,
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/recurso-revision",
        name: "Recursos de Revisión",
        icon: <FileSyncOutlined/>,
        sidebar: "single",
        ver: 7,
        routes: [
          {
            path: "/",
            component: RecursoRevisionLista,
          },
          {
            path: "/detalle",
            component: RecursoRevisionDetalle,
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/denuncia",
        name: "Denuncias",
        icon: <FileUnknownOutlined/>,
        sidebar: "single",
        ver: 8,
        routes: [
          {
            path: "/",
            component: DenunciaLista,
          },
          {
            path: "/detalle",
            component: DenunciaDetalle,
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/buzon",
        name: "Buzón",
        icon: <MailOutlined/>,
        sidebar: "single",
        ver: 9,
        routes: [
          {
            path: "/",
            component: BuzonLista,
          },
          {
            path: "/detalle",
            component: BuzonDetalle,
          },
        ],
      },
    ],
  },
  {
    layout: "dashboard",
    path: "/buzon-municipios",
    name: "Buzón Municipios",
    icon: <MailOutlined/>,
    sidebar: "single",
    ver: 28,
    routes: [
      {
        path: "/",
        component: BuzonMunicipioLista,
      },
      {
        path: "/detalle",
        component: BuzonMunicipioDetalle,
      },
    ],
  },
  {
    layout: "dashboard",
    path: "/publicacion-nota",
    name: "Publicaciones Portal",
    icon: <MailOutlined/>,
    sidebar: "single",
    ver: 31,
    routes: [
      {
        path: "/",
        component: PublicacionNota,
      },
      {
        path: "/detalle",
        component: PublicacionNotaDetalle,
      },
    ],
  },
  {
    layout: "dashboard",
    path: "/documentacion",
    name: "Documentación",
    icon: <ExceptionOutlined/>,
    sidebar: "collapse",
    ver: 3,
    routes: [
      {
        layout: "dashboard",
        path: "/transparencia",
        name: "Transparencia",
        icon: <FileSearchOutlined/>,
        sidebar: "single",
        ver: 17,
        routes: [
          {
            path: "/",
            component: TransparenciaLista,
          },
          {
            path: "/nuevo",
            component: TransparenciaDetalle,
          },
          {
            path: "/detalle",
            component: TransparenciaDetalle,
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/documentos",
        name: "Documentos",
        icon: <FolderOpenOutlined/>,
        sidebar: "single",
        ver: 18,
        routes: [
          {
            path: "/",
            component: DocumentoLista,
          },
          {
            path: "/nuevo",
            component: DocumentoDetalle,
          },
          {
            path: "/detalle",
            component: DocumentoDetalle,
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/documentos-portal-administrativa",
        name: "Documentos Web",
        icon: <FolderOpenOutlined/>,
        sidebar: "single",
        ver: 20,
        routes: [
          {
            path: "/",
            component: DocumentoAdministrativaLista,
          },
          {
            path: "/nuevo",
            component: DocumentoAdministrativaDetalle,
          },
          {
            path: "/detalle",
            component: DocumentoAdministrativaDetalle,
          },
        ],
      },
    ],
  },
  {
    layout: "dashboard",
    path: "/revision-obligaciones",
    name: "Revisión de Obligaciones",
    icon: <TeamOutlined/>,
    sidebar: "single",
    ver: 19,
    routes: [
      {
        path: "/",
        component: RevisionObligacionesLista,
      },
      {
        path: "/nuevo",
        component: RevisionObligacionesDetalle,
      },
      {
        path: "/detalle",
        component: RevisionObligacionesDetalle,
      },
    ],
  },
  {
    layout: "dashboard",
    path: "/administracion",
    name: "Administración",
    icon: <SettingOutlined/>,
    sidebar: "collapse",
    ver: 2,
    routes: [
      {
        layout: "dashboard",
        path: "/publicaciones",
        name: "Publicaciones",
        icon: <AuditOutlined/>,
        sidebar: "collapse",
        routes: [
          {
            layout: "dashboard",
            path: "/tipos",
            name: "Tipos",
            icon: <FileWordOutlined/>,
            sidebar: "single",
            ver: 10,
            routes: [
              {
                path: "/",
                name: "Formato de Acta",
                component: PublicacionTipo,
              },
              {
                path: "/detalle",
                name: "Editar",
                component: PublicacionTipoDetalle,
              },
            ],
          },
          {
            layout: "dashboard",
            path: "/sub-tipos",
            name: "Sub Tipos",
            icon: <FileTextOutlined/>,
            sidebar: "single",
            ver: 10,
            routes: [
              {
                path: "/",
                name: "Variables",
                component: PublicacionSubTipo,
              },
              {
                path: "/detalle",
                name: "Editar",
                component: PublicacionSubTipoDetalle,
              },
            ],
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/ponencias",
        name: "Ponencias",
        icon: <NotificationOutlined/>,
        sidebar: "single",
        ver: 10,
        routes: [
          {
            path: "/",
            component: Ponencias,
          },
          {
            path: "/nueva",
            component: PonenciaDetalle,
          },
          {
            path: "/detalle",
            component: PonenciaDetalle,
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/entes",
        name: "Entes",
        icon: <GoldOutlined/>,
        sidebar: "single",
        ver: 10,
        routes: [
          {
            path: "/",
            component: EnteLista,
          },
          {
            path: "/nueva",
            component: EnteDetalle,
          },
          {
            path: "/detalle",
            component: EnteDetalle,
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/articulos",
        name: "Artículos",
        icon: <AccountBookOutlined/>,
        sidebar: "single",
        ver: 11,
        routes: [
          {
            path: "/",
            component: ArticuloLista,
          },
          {
            path: "/nueva",
            component: ArticuloDetalle,
          },
          {
            path: "/detalle",
            component: ArticuloDetalle,
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/categorias",
        name: "Categorías",
        icon: <ReconciliationOutlined/>,
        sidebar: "single",
        ver: 12,
        routes: [
          {
            path: "/",
            component: CategoriaLista,
          },
          {
            path: "/nueva",
            component: CategoriaDetalle,
          },
          {
            path: "/detalle",
            component: CategoriaDetalle,
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/portal-categorias",
        name: "Documentos Web Categorías",
        icon: <ReconciliationOutlined/>,
        sidebar: "single",
        ver: 12,
        routes: [
          {
            path: "/",
            component: PortalCategoriaLista,
          },
          {
            path: "/nueva",
            component: PortalCategoriaDetalle,
          },
          {
            path: "/detalle",
            component: PortalCategoriaDetalle,
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/portal-sub-categorias",
        name: "Documentos Web Sub Categorías",
        icon: <ReconciliationOutlined/>,
        sidebar: "single",
        ver: 12,
        routes: [
          {
            path: "/",
            component: PortalSubCategoriaLista,
          },
          {
            path: "/nueva",
            component: PortalSubCategoriaDetalle,
          },
          {
            path: "/detalle",
            component: PortalSubCategoriaDetalle,
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/usuarios",
        name: "Usuarios",
        icon: <UserOutlined/>,
        sidebar: "single",
        ver: 14,
        routes: [
          {
            path: "/",
            component: UsuarioLista,
          },
          {
            path: "/detalle",
            component: UsuarioDetalle,
          },
          {
            path: "/nuevo",
            component: UsuarioDetalle,
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/permisos",
        name: "Permisos",
        icon: <AppstoreOutlined/>,
        sidebar: "single",
        ver: 15,
        routes: [
          {
            path: "/",
            component: Permisos,
          },
          {
            path: "/nuevo",
            component: PermisosForm,
          },
          {
            path: "/editar",
            component: PermisosForm,
          },
          {
            path: "/modulo-nuevo",
            component: PermisosModuloForm,
          },
          {
            path: "/modulo-editar",
            component: PermisosModuloForm,
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/secciones",
        name: "Secciones",
        icon: <BlockOutlined/>,
        ver: 13,
        routes: [
          {
            path: "/",
            component: SeccionLista,
          },
          {
            path: "/nueva",
            component: PublicacionDetalle,
          },
          {
            path: "/detalle",
            component: SeccionDetalle,
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/publicaciones",
        name: "Publicaciones",
        icon: <BookOutlined/>,
        ver: 16,
        routes: [
          {
            path: "/",
            component: PublicacionLista,
          },
          {
            path: "/nueva",
            component: PublicacionDetalle,
          },
          {
            path: "/detalle",
            component: PublicacionDetalle,
          },
        ],
      },
    ],
  },
  ...sharedRoutes,
];

const publicRoutes = [...sharedRoutes];

export {dashboardRoutes, publicRoutes};

