import React from "react";
import {
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Modal,
  Button,
  DatePicker, Select as SelectAntd, notification
} from "antd";
import {
  ClearOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined, FileExcelOutlined,
  FileSearchOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SearchOutlined
} from "@ant-design/icons";
import {ActionsButton} from "../../components";
import SimpleTableLayout from "../../components/layouts/SimpleTableLayout";
import {useApp, useAuth, useModels} from "../../hooks"
import {useHistory} from "react-router-dom"
import moment from "moment";
import StatusResponse from "../../services/statusResponse";
import {trimestres} from "../../constants";

const viewTitle = "Transparencia";
const emptyText = "No hay transparencias registradas...";
const {Option, OptGroup} = Select;

export const TransparenciaLista = () => {
  const {user} = useAuth()
  const rol = user?.rol
  const esAdmin = rol === "admin"
  const {REACT_APP_API_URL: baseUrl} = process.env;
  const history = useHistory()
  const [form] = Form.useForm();
  const {token} = useApp();
  const [pageSize, setPageSize] = React.useState(10)
  const [fracciones, setFracciones] = React.useState([])
  const [idArticuloPadre, setIdArticuloPadre] = React.useState(null)
  const [idFraccion, setIdFraccion] = React.useState(null);
  const [anio, setAnio] = React.useState(null);
  const [trimestre, setTrimestre] = React.useState(null);
  const [q, setQ] = React.useState('');
  // eslint-disable-next-line no-unused-vars
  const [idEnte, setIdEnte] = React.useState(null);

  // extraParams: {
  //   articulo: idArticuloPadre || null,
  //   fraccion: idFraccion || null,
  //   anio: anio?.format("YYYY") || null,
  //   trimestre: trimestre || null,
  //   ente: user?.enteUsuario?.idEnte || null,
  //   q: q || null
  // }


  const [request, setRequest] = React.useState({})
  const [request2, setRequest2] = React.useState({})
  const [request3, setRequest3] = React.useState({})

  const breadcrumb = [
    {
      name: "Transparencia",
      to: "/documentacion/transparencia",
      icon: <FileSearchOutlined/>
    }
  ];

  const buttonData = {
    text: "Nuevo Documento",
    icon: <PlusCircleOutlined/>,
    to: () => history.push("/documentacion/transparencia/nueva"),
    props: {
      shape: "round",
    }
  }

  const [
    articulos,
    articulosLoading
  ] = useModels(request2)

  const [
    entes,
    entesLoading,
    // eslint-disable-next-line no-unused-vars
    entesError, entesPage, refreshEntes
  ] = useModels(request3);

  React.useEffect(() => {
    setRequest2({
      name: 'articulo',
      ordenar: 'id-asc',
      limite: -1,
      expand: 'fracciones',
      extraParams: {
        padres: 1
      }
    })
    setRequest3({
      name: 'ente',
      ordenar: 'id-asc',
      limite: -1,
    })
    return () => {
      setRequest2({})
      setRequest3({})
    }
  }, [])

  const [
    models,
    modelsLoading,
    // eslint-disable-next-line no-unused-vars
    modelsError,
    modelsPage,
    refreshModels
  ] = useModels(request);

  React.useEffect(() => {
    const buscar = {
      idEnte: idEnte || user?.enteUsuario?.idEnte || null,
      q: q || null,
      articulo: idArticuloPadre || null,
      fraccion: idFraccion || null,
      trimestre: trimestre || null,
      anio: anio ? anio?.format("YYYY") : null,
    }
    if (buscar?.q === null)
      delete buscar.q
    if (buscar?.articulo === null)
      delete buscar.articulo
    if (buscar?.fraccion === null)
      delete buscar.fraccion
    if (buscar?.trimestre === null)
      delete buscar.trimestre
    if (buscar?.anio === null)
      delete buscar.anio

    setRequest({
      name: 'transparencia',
      ordenar: 'id-desc',
      limite: pageSize,
      expand: 'articulo, articulo.padre',
      extraParams: buscar
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sinDefinir = <i>-Sin definir-</i>

  const renderSimple = (prop) => prop || sinDefinir;

  const modalDelete = row => {
    if (!row?.id) return;
    Modal.confirm({
      title: "Eliminar Transparencia",
      content: `¿Está seguro de eliminar el documento "${row?.titulo}"?`,
      icon: <DeleteOutlined style={{color: '#ff0000'}}/>,
      okText: 'Eliminar',
      okType: 'default',
      okButtonProps: {
        type: 'danger',
      },
      onCancel: () => {
      },
      cancelText: 'Cancelar',
      onOk: () => {
        return new Promise(async (resolve, reject) => {
          const res = await StatusResponse.delete("transparencia", {id: row?.id})
          if (res.status === 200) {
            refreshModels()
            let secondsToGo = 2
            const modal = Modal.success({
              title: res?.mensaje,
              cancelButtonProps: {style: {display: 'none'}},
              okButtonProps: {style: {display: 'none'}}
            })
            const timer = setInterval(() => {
              secondsToGo -= 1
            }, 1000)
            setTimeout(() => {
              clearInterval(timer)
              modal.destroy()
            }, secondsToGo * 1000)
            resolve()
          }
          reject()
        })
      }
    });
  }

  const columns = [
    {
      title: "Acciones",
      key: "id",
      dataIndex: "id",
      render: (_, row) => (
        <ActionsButton
          key={row}
          options={[
            {
              name: "Ver detalle",
              icon: <EyeOutlined/>,
              onClick: () => modalInfo(row)
            },
            {
              name: "Descargar",
              icon: <DownloadOutlined/>,
              onClick: () => window.open(row?.ruta, "_blank"),
              styleProps: {
                color: "#00a854"
              }
            },
            {
              name: "Editar información",
              icon: <EditOutlined/>,
              onClick: () => history.push(`transparencia/detalle?id=${row?.id}`),
              styleProps: {
                color: "#4495bb"
              }
            },
            {
              name: "Eliminar",
              icon: <DeleteOutlined/>,
              onClick: () => modalDelete(row),
              styleProps: {
                color: "#f5222d"
              }
            }
          ]}
        />
      ),
      align: 'center',
      // width: 100
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      // width: 30,
      render: renderSimple
    },
    {
      title: "Artículo",
      dataIndex: "articulo",
      key: "articulo",
      // width: 50,
      render: prop => prop?.padre ? prop?.padre?.titulo : sinDefinir
    },
    {
      title: "Fracción",
      dataIndex: "articulo",
      key: "articulo",
      // width: 150,
      render: prop => prop ? prop?.titulo : sinDefinir
    },
    {
      title: "Título",
      // width: 100,
      dataIndex: "titulo",
      key: "titulo",
      render: renderSimple
    },
    {
      title: "Descripción",
      // width: 90,
      dataIndex: "descripcion",
      key: "descripcion",
      render: (_, item) => item?.descripcion?.substr(0, 20) + "..."
    },
    Table.EXPAND_COLUMN,
    {
      title: "Fecha",
      // width: 100,
      dataIndex: "fecha",
      key: "fecha",
      render: prop => prop ? moment(prop).format("DD/MM/YYYY HH:mm:ss") : sinDefinir
    },
  ];

  const ContentModal = ({row}) => {
    return (
      <div>
        <Descriptions
          bordered
          column={1}
          size="small"
          title="Información del artículo"
        >
          <Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Artículo">
            {row?.articulo?.padre?.titulo || sinDefinir}
          </Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Fracción">
            {row?.articulo?.titulo || sinDefinir}
          </Descriptions.Item>
        </Descriptions>
        <Divider/>
        <Descriptions
          bordered
          column={1}
          size="small"
          title="Información del documento"
        >
          <Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Título">
            {row?.titulo || sinDefinir}
          </Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Descripción">
            {row?.descripcion || sinDefinir}
          </Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Fecha">
            {row?.fecha ? moment(row?.fecha).format("DD/MM/YYYY HH:mm:ss") : sinDefinir}
          </Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Documento">
            <Button
              icon={<DownloadOutlined/>}
              type="link"
              onClick={() => window.open(row?.ruta, "_blank")}
            >
              Abrir
            </Button>
          </Descriptions.Item>
        </Descriptions>
      </div>
    )
  }

  const onImprimirExcelFaltantes = () => {
    let busqueda = "";
    let error = "";
    const {trimestre, anio} = form.getFieldsValue();

    busqueda += "idEnte=" + user?.ente?.id + "&"

    if (trimestre) {
      busqueda += "trimestre=" + trimestre + "&"
    } else
      error += "Trimestre, "

    if (anio) {
      busqueda += "anio=" + anio.format('YYYY') + "&"
    } else
      error += "Año, "

    if (error !== "") {
      notification.error({
        message: "Atención",
        description: <>Seleccione un {error} para continuar</>,
        placement: 'bottomRight'
      });
      return;
    }

    window.open(`${baseUrl.replace("/v1/", "")}/reportes/excel-revision-faltantes?${busqueda}access-token=${token}&r=1`, '_blank')
  };

  const modalInfo = (v) => {

    Modal.confirm({
      title: "",
      icon: null,
      content: <ContentModal row={v}/>,
      okText: "Ok",
      cancelButtonProps: {style: {display: 'none'}},
      okButtonProps: {type: "primary"},
      closable: true,
      width: 1000
    });
  };

  const onChangeCallback = React.useCallback(
    async (page, size) => {
      setPageSize(size);
      await refreshModels(true, {
        ordenar: 'id-desc',
        pagina: page,
        limite: size,
        extraParams: {
          articulo: idArticuloPadre && idArticuloPadre,
          fraccion: idFraccion && idFraccion,
        }
      })
    }, [idArticuloPadre, idFraccion, refreshModels]);

  const getPageSizeOptions = React.useMemo(() => {
    const options = [10, 20, 50, 100, 200, 500].filter(val => val <= modelsPage?.total);
    if (options[options.length - 1] !== modelsPage?.total) {
      options.push(modelsPage?.total);
    }
    return options
  }, [modelsPage]);

  const config = React.useMemo(() => {
    if (modelsPage) {
      let size = pageSize;
      return {
        total: modelsPage.total,
        pageSize: modelsPage.limite,
        onShowSizeChange: (_, newSize) => (size = newSize),
        onChange: async (v) => await onChangeCallback(v, size),
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} documentos.`,
        locale: {items_per_page: '/ página'},
        pageSizeOptions: getPageSizeOptions,
        showSizeChanger: true
      }
    }
    return null;
  }, [onChangeCallback, pageSize, modelsPage, getPageSizeOptions]);


  const onImprimirExcel = () => {
    const values = form.getFieldsValue();
    let busqueda = "";
    let error = "";
    let obligatorio = 0;

    if (user?.enteUsuario?.idEnte) {
      busqueda += "idEnte=" + user?.enteUsuario?.idEnte + "&"
    } else {
      error += ' Ente,';
      obligatorio++;
    }

    if (values.idArticulo) {
      busqueda += "idArticulo=" + values.articulo + "&"
    }

    if (values.idFraccion) {
      busqueda += "idFraccion=" + values.fraccion + "&"
    }

    if (values.trimestre) {
      busqueda += "trimestre=" + values.trimestre + "&"
    } else {
      error += ' Trimestre,';
      obligatorio++;
    }

    if (values.anio) {
      busqueda += "anio=" + moment(values.anio).format('YYYY') + "&"
    } else {
      error += ' Año,';
      obligatorio++;
    }

    if (obligatorio > 0) {
      notification.error({
        message: "Atención",
        description: <>Seleccione una {error} para continuar </>,
        placement: 'bottomRight'
      });
      return;
    }

    window.open(`${baseUrl.replace("/v1/", "")}/reportes/excel-revision?${busqueda}access-token=${token}&r=1`, '_blank')
  };

  const onFinish = async (values) => {
    const {buscar, articulo, fraccion, trimestre, anio, ente} = values;
    const idEnte = ente ? ente : user?.enteUsuario?.idEnte
    const obj = {
      idEnte: idEnte || null,
      q: buscar || null,
      articulo: articulo || null,
      fraccion: fraccion || null,
      trimestre: trimestre || null,
      anio: anio ? anio.format("YYYY") : null,
    }
    if (obj?.q === null)
      delete obj.q
    if (obj?.articulo === null)
      delete obj.articulo
    if (obj?.fraccion === null)
      delete obj.fraccion
    if (obj?.trimestre === null)
      delete obj.trimestre
    if (obj?.anio === null)
      delete obj.anio

    setRequest({
      name: 'transparencia',
      ordenar: 'id-desc',
      limite: pageSize,
      expand: 'articulo, articulo.padre',
      extraParams: obj
    })
  }

  const SearchForm = () => (
    <Form
      layout="vertical"
      form={form}
      style={{marginBottom: '1rem'}}
      onFinish={onFinish}
    >
      <Row gutter={16}>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <Form.Item label="Buscar:" name="buscar">
            <Input placeholder="Buscar por título, descripción, etc..."/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <Form.Item
            label="Artículo"
            name="articulo"
          >
            <Select
              allowClear
              showSearch
              listItemHeight={10}
              listHeight={250}
              loading={articulosLoading}
              defaultValue={form.getFieldValue('articulo')}
              onDeselect={() => setFracciones([])}
              placeholder="Selecciona un artículo padre"
              onChange={(_, opt) => setFracciones(opt?.item?.fracciones.sort((a, b) => a.orden > b.orden ? 1 : -1))}
            >
              {
                rol !== 'organismo-agua' && (
                  <OptGroup label="Leyes y Artículos">
                    {
                      articulos ?
                        articulos
                          .filter(item => item?.ambito === user?.ente?.ambito)
                          .filter(f => !f?.ambito.includes("Informativo Portal"))
                          .map((m, i) => <Option key={`leyes-articulos-${i}`} value={m?.id} item={m}>
                            {`${m.ambito} - ${m.titulo}`}
                          </Option>)
                        : []
                    }
                  </OptGroup>
                )
              }
              {
                rol !== "sindicato" && rol !== "organismo-agua" && (
                  <OptGroup label="Información Portal">
                    {
                      articulos ?
                        articulos.filter(f => {
                          if (!f?.ambito.includes("Informativo Portal")) {
                            return false
                          }
                          if (rol === "municipio") {
                            return !f?.titulo.includes("Pleno")
                          }
                          return true
                        })
                          .map((m, i) => <Option key={`informativo-portal-${i}`} value={m?.id} item={m}>
                            {`${m.ambito} - ${m.titulo}`}
                          </Option>)
                        : []
                    }
                  </OptGroup>
                )
              }
              {
                rol === "organismo-agua" && (
                  <OptGroup label="Información Portal">
                    {
                      articulos ?
                        articulos.filter(f => {
                          if (!f?.ambito.includes("Informativo Portal")) {
                            return false
                          }
                          return f?.titulo.includes("Organismo")
                        })
                          .map((m, i) => <Option key={`informativo-portal-${i}`} value={m?.id} item={m}>
                            {`${m.ambito} - ${m.titulo}`}
                          </Option>)
                        : []
                    }
                  </OptGroup>
                )
              }
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <Form.Item
            label="Fracción:"
            name="fraccion"
            rules={[{required: true, message: 'Por favor seleccione una fracción'}]}
          >
            <Select
              allowClear
              showSearch
              filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              listItemHeight={10}
              listHeight={250}
              options={fracciones ? fracciones.map((m, i) => ({
                label: `${m.titulo}`,
                value: m.id,
                key: i,
                item: m
              })) : []}
              placeholder="Selecciona una fracción"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={3} lg={3} xl={3}>
          <Form.Item
            label="Trimestre:"
            name="trimestre"
            rules={[{required: true, message: 'Por favor seleccione un trimestre'}]}
          >
            <SelectAntd
              showSearch
              allowClear
              filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              listItemHeight={10}
              listHeight={250}
              options={trimestres?.map((m, i) => ({
                label: `${m.label}`,
                value: m.key,
                key: m.key,
                item: m.key
              }))}
              placeholder="Selecciona un trimestre"
              value={trimestre}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={3} lg={3} xl={3}>
          <Form.Item label="Año:" name="anio">
            <DatePicker
              picker="year"
              allowClear
              placeholder={"Año"}
              style={{width: "100%"}}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={2} lg={2} xl={2}>
          <Space
            justify="space-between"
            style={{marginTop: '.5rem'}}
          >
            <Tooltip title="Buscar">
              <Button
                icon={<SearchOutlined/>}
                style={{marginTop: '1.5rem'}}
                shape="circle"
                type="primary"
                disabled={modelsLoading}
                htmlType="submit"
              />
            </Tooltip>
            <Tooltip title="Limpiar">
              <Button
                icon={<ClearOutlined/>}
                style={{marginTop: '1.5rem'}}
                shape="circle"
                type="default"
                disabled={modelsLoading}
                onClick={() => {
                  form.resetFields();
                  setQ('');
                  setIdArticuloPadre(null);
                  setIdFraccion(null);
                  setAnio(null);
                  setTrimestre(null);
                }}
              />
            </Tooltip>
            <Tooltip title="Excel">
              <Button
                icon={<FileExcelOutlined/>}
                style={{
                  color: '#217346',
                  borderColor: '#217346',
                  marginLeft: '1.5rem',
                  bottom: '2rem',
                  zIndex: 1
                }}
                type="default"
                // disabled={}
                onClick={() => {
                  onImprimirExcel();
                }}
              >
                Excel
              </Button>
            </Tooltip>
          </Space>
        </Col>
        <Col xs={24} sm={24} md={2} lg={2} xl={2}>
          <Button
            icon={<PlusOutlined/>}
            onClick={() => history.push(`transparencia/nuevo`)}
            style={{marginTop: '1.5rem'}}
            type="primary"
          >
            Agregar
          </Button>
        </Col>
        <Col xs={24} sm={24} md={2} lg={2} xl={2} hidden={user?.ente?.ambito !== "Municipal"}>
          <Button
            icon={<FileExcelOutlined/>}
            onClick={() => onImprimirExcelFaltantes()}
            style={{marginTop: '1.5rem'}}
            type="primary"
          >
            Faltantes
          </Button>
        </Col>
        {
          esAdmin && (
            <Col xs={24} sm={24} md={8}>
              <Form.Item label="Ente" name="ente">
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  listItemHeight={10}
                  listHeight={250}
                  loading={entesLoading}
                  placeholder="Selecciona un ente"
                >
                  {
                    entes ?
                      entes.map((m, i) =>
                        <Option
                          key={`entes-${i}`}
                          value={m?.id}
                          item={m}
                        >
                          {m?.titulo}
                        </Option>
                      )
                      : []
                  }
                </Select>
              </Form.Item>
            </Col>
          )
        }
      </Row>
    </Form>
  )

  return (
    <SimpleTableLayout
      buttonData={buttonData}
      breadcrumbItems={breadcrumb}
      title={viewTitle}
      emptyText={emptyText}
      withSearchButton={false}
      children={
        <>
          <SearchForm/>
          <Table
            columns={columns}
            dataSource={models}
            loading={modelsLoading}
            locale={{emptyText}}
            pagination={config}
            expandable={{
              expandedRowRender: record => <p style={{margin: 0}}>{record.descripcion}</p>,
            }}
            scroll={{x: 1300}}
            rowKey="id"
            size="small"
          />
        </>
      }
    />
  );
};