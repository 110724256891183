import React, {useEffect, useState} from 'react'
import {
  Row,
  Col,
  Form,
  Input,
  Modal,
  Space,
  Button,
  Tooltip,
  Typography, Switch
} from 'antd'
import {DeleteOutlined, SaveOutlined, SearchOutlined} from '@ant-design/icons'
import StatusResponse from "../../../services/statusResponse";
import {Respuestas} from "../../../utilities/Respuesta";
import {useHistory} from "react-router-dom";
import ImageCrop from "../../../components/ImageCrop";
import {EditorTexto, Uploader} from "../../../components";
import HttpService from "../../../services/httpService";
import TextArea from "antd/es/input/TextArea";


export const Formulario = ({model = null, id, alTerminar, endPoint, url, idModelo, media, editando,}) => {

  const [form] = Form.useForm();
  const history = useHistory();
  const {Title} = Typography;

  const [guardando, setGuardando] = useState(false);
  const [directorioOic, setDirectorioOic] = useState(false);
  const [listaArchivosImagen, setListaArchivosImagen] = useState([])
  const [listaArchivosCurriculum, setListaArchivosCurriculum] = useState([])
  const [listaArchivosDeclaracion, setListaArchivosDeclaracion] = useState([])
  const [referenciasImagen, setReferenciasImagen] = useState([])
  const [referenciasCurriculum, setReferenciasCurriculum] = useState([])
  const [referenciasDeclaracion, setReferenciasDeclaracion] = useState([])

  const onFinish = async (values) => {
    let _body = {...values};

    if (editando) {
      _body.id = id;
    }
    _body.archivos = []

    if (listaArchivosCurriculum.length > 0)
      referenciasCurriculum.map(file => _body.archivos.push({...file, tipo: 'curriculum'}));

    if (listaArchivosDeclaracion.length > 0)
      referenciasDeclaracion.map(file => _body.archivos.push({...file, tipo: 'declaracion'}));

    if (listaArchivosImagen.length > 0)
      referenciasImagen.map(file => _body.archivos.push({...file, tipo: 'imagen'}));

    try {
      setGuardando(true);
      const respuesta = await StatusResponse.post(`${endPoint}/guardar`, _body);
      if (Respuestas(respuesta)) {
        history.push(`${url}`)
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGuardando(false);
    }
  }

  const handleRemove = (file) => {
    Modal.confirm({
      title: '¿Desea eliminar esta imagen?',
      icon: <DeleteOutlined style={{color: 'red'}}/>,
      content: file.name,
      async onOk() {
        try {
          const res = await HttpService.delete('v1/media', {
            id: file.id,
          })

          Respuestas(res)
          setListaArchivosImagen([])
          setListaArchivosCurriculum([])
          setListaArchivosDeclaracion([])

        } catch (error) {
          console.log('En handleRemove()', error)
        }
      },
      onCancel() {
        return false
      },
    })
    return false
  }

  useEffect(() => {
    if (model) {
      form.setFieldsValue({...model})

      console.log(model)
      setDirectorioOic(model?.directorioOIC);

      setListaArchivosCurriculum(model?.mediaDirectorio?.filter(item => item?.tipo === 'curriculum').map(item => ({
        ...item.media,
        url: item.media.ruta,
        name: item.media.nombre
      })))

      setListaArchivosImagen(model?.mediaDirectorio?.filter(item => item?.tipo === 'imagen').map(item => ({
        ...item.media,
        url: item.media.ruta,
        name: item.media.nombre
      })))

      setListaArchivosDeclaracion(model?.mediaDirectorio?.filter(item => item?.tipo === 'declaracion').map(item => ({
        ...item.media,
        url: item.media.ruta,
        name: item.media.nombre
      })))


      setReferenciasCurriculum(model?.mediaDirectorio?.filter(item => item?.tipo === 'curriculum').map(item => ({
        ...item.media,
      })))

      setReferenciasImagen(model?.mediaDirectorio?.filter(item => item?.tipo === 'imagen').map(item => ({
        ...item.media,
        url: item.media.ruta
      })))

      setReferenciasDeclaracion(model?.mediaDirectorio?.filter(item => item?.tipo === 'declaracion').map(item => ({
        ...item.media,
      })))

    } else {
      form.resetFields()
    }
  }, [form, model])

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout='vertical'
    >
      <Row>
        <Col
          xs={24}
          sm={24}
          md={4}
          lg={4}
        >
          <ImageCrop
            maxCount={1}
            endPoint={"subir-archivo"}
            listaArchivos={listaArchivosImagen}
            setListaArchivos={setListaArchivosImagen}
            setReferencias={setReferenciasImagen}
            className={'img-cropper'}
          />
        </Col>
        <Col
          span={20}
        >
          <Row gutter={[16, 0]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
            >
              <Form.Item
                label={<strong>Orden</strong>}
                name="orden"
              >
                <Input
                  size={'large'}
                  placeholder="Orden"
                />
              </Form.Item>
            </Col>
            <Col
              xs={0}
              sm={0}
              md={12}
              lg={12}/>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
            >
              <Form.Item
                label={<strong>Nombre</strong>}
                name="nombre"
              >
                <Input
                  size={'large'}
                  placeholder="Nombre"
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
            >
              <Form.Item
                label={<strong>Puesto</strong>}
                name="puesto"
              >
                <Input
                  size={'large'}
                  placeholder="Puesto"
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
            >
              <Form.Item
                label={<strong>Correo Electronico</strong>}
                name="correo"
              >
                <Input
                  size={'large'}
                  placeholder="Correo"
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={20}
              lg={20}
            >
              <Form.Item
                label={<strong>Teléfono</strong>}
                name="telefono"
              >
                <Input
                  size={'large'}
                  placeholder="Teléfono"
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={4}
              lg={4}
            >
              <Form.Item
                label={<strong>Directorio OIC</strong>}
                name="directorioOIC"
              >
                <Switch
                  checkedChildren={"Visible"}
                  unCheckedChildren={"No visible"}
                  size={'large'}
                  checked={directorioOic}
                  onChange={() => setDirectorioOic(!directorioOic)}
                  placeholder="Teléfono"
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
            >
              <Form.Item
                label={<strong>Curriculum</strong>} w
                name="resena"
              >
                <EditorTexto/>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
        >
          <Title level={5}>Declaracion</Title>
          <Uploader
            listaArchivos={listaArchivosDeclaracion}
            max={1}
            endPoint={"subir-archivo"}
            endPointEliminar={'media/eliminar'}
            setListaArchivos={setListaArchivosDeclaracion}
            setReferencias={setReferenciasDeclaracion}
            archivoMaximo={1}
            onRemove={(file) => handleRemove(file)}
          />
        </Col>
      </Row>
      <Row gutter={10}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          align={'end'}
        >
          <Space
            justify="space-between"
            style={{marginTop: '.5rem'}}
          >
            <Tooltip title="Guardar">
              <Button
                size={'large'}
                htmlType="submit"
                icon={<SaveOutlined/>}
                style={{marginTop: '1.5rem'}}
                type="primary"
              >
                Guardar
              </Button>
            </Tooltip>
          </Space>
        </Col>
      </Row>
    </Form>
  )
}
