import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import {Col, DatePicker, Form, Row, Select as SelectAntd, Switch} from 'antd'
import {ButtonGroup} from "../../components";
import {useAuth, useModels} from "../../hooks";
import {trimestres} from "../../constants";

const {RangePicker} = DatePicker
const {Option, OptGroup} = SelectAntd;

const Filtros = ({btnGroup, formFiltros, cargado, setCargado}) => {
  const {user} = useAuth()
  const rol = user?.rol
  const [requestArticulos, setRequestArticulos] = useState({})
  const [requestEntes, setRequestEntes] = useState({})
  const [fracciones, setFracciones] = useState([])
  const [articulosAmbitos, setArticulosAmbitos] = useState([])
  const [ambitoEnte, setAmbitoEnte] = useState('');

  useEffect(() => {
    setRequestArticulos({
      name: 'articulo',
      ordenar: 'id-asc',
      limite: -1,
      expand: 'fracciones',
      extraParams: {
        padres: 1,
        tipo: "recursoRevision"
      }
    })
    return () => setRequestArticulos({})
  }, [])

  useEffect(() => {
    setRequestEntes({
      name: 'ente',
      ordenar: 'id-asc',
      limite: -1,
      extraParams: {
        tipo: "recursoRevision"
      }
    })
    return () => setRequestEntes({})
  }, [])

  useEffect(() => {
    if (ambitoEnte)
      setArticulosAmbitos(articulos.filter(art => art?.ambito === ambitoEnte));
  }, [ambitoEnte])

  const [
    articulos,
    articulosLoading
  ] = useModels(requestArticulos)

  const [
    entes,
    entesLoading
  ] = useModels(requestEntes)


  return (
    <Form
      form={formFiltros}
      layout="vertical"
    >
      <Row gutter={10}>
        <Col span={20}>
          <Row gutter={10}>
            <Col>
              <Switch
                checkedChildren="Cargados"
                unCheckedChildren="No Cargados"
                onChange={() => setCargado(!cargado)}
                checked={cargado}
              />
            </Col>
            <Col span={7}>
              <Form.Item label="" name="idEnte">
                <SelectAntd
                  size="large"
                  optionFilterProp="children"
                  showSearch
                  allowClear
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  listItemHeight={10}
                  listHeight={250}
                  loading={entesLoading}
                  onChange={(item, row) => {
                    console.log(entes.find(ente => ente?.id === item));
                    if (item)
                      setAmbitoEnte(entes.find(ente => ente?.id === item)?.ambito)
                  }}
                  options={entes.map((m, i) => ({
                    label: `${m.titulo}`,
                    value: m.id,
                    key: i,
                    item: m
                  }))}
                  placeholder="Seleccione un ente"
                />
              </Form.Item>
            </Col>
            <Col
              span={7}
            >
              <Form.Item label="" name="idArticulo">
                <SelectAntd
                  size="large"
                  optionFilterProp="children"
                  showSearch
                  allowClear
                  filterOption={(input, option) => {
                    if (option.children) {
                      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    return false
                  }}
                  listItemHeight={10}
                  listHeight={250}
                  loading={articulosLoading}
                  placeholder="Selecciona un artículo"
                  onChange={() => {
                    setFracciones([])
                    formFiltros.setFieldsValue({idFraccion: ""})
                  }}
                  onSelect={(_, opt) => setFracciones(opt?.item?.fracciones.sort((a, b) => a?.orden > b?.orden ? 1 : -1))}
                >
                  <OptGroup label="Leyes y Artículos">
                    {
                      articulosAmbitos
                        .filter(art => art?.ambito === ambitoEnte)
                        .map((m, i) => <Option
                          key={`leyes-articulos-${i}`}
                          value={m?.id}
                          item={m}
                        >
                          {`${m.ambito} - ${m.titulo}`}
                        </Option>)
                    }
                  </OptGroup>
                </SelectAntd>
              </Form.Item>
            </Col>
            <Col
              span={7}
            >
              <Form.Item label="" name="idFraccion">
                <SelectAntd
                  size="large"
                  optionFilterProp="children"
                  showSearch
                  allowClear
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  listItemHeight={10}
                  listHeight={250}
                  options={fracciones ? fracciones.map((m, i) => ({
                    label: `${m.titulo}`,
                    value: m.id,
                    key: i,
                    item: m
                  })) : []}
                  rules={[{required: true, message: 'Por favor seleccione una fracción'}]}
                  placeholder="Selecciona una fracción"
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="" name="trimestre">
                <SelectAntd
                  size="large"
                  FilterProp="children"
                  showSearch
                  allowClear
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  listItemHeight={10}
                  listHeight={250}
                  options={trimestres?.map((m, i) => ({
                    label: `${m.label}`,
                    value: m.key,
                    key: m.key,
                    item: m.key
                  }))}
                  rules={[{required: true, message: 'Por favor seleccione un trimestre'}]}
                  placeholder="Selecciona un trimestre"
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="" name="anio">
                <DatePicker
                  size="large"
                  picker="year"
                  allowClear
                  placeholder={"Año"}
                  style={{width: "100%"}}
                />
              </Form.Item>
            </Col>
            <Col
              span={8}
            >
              <Form.Item label="" name="rango">
                <RangePicker
                  disabled={!cargado}
                  size="large"
                  allowClear
                  format="DD/MM/YYYY"
                  placeholder={['Fecha inicio', 'Fecha fin']}
                  style={{width: '100%'}}
                />
              </Form.Item>
            </Col>
            <Col
              span={3}
            >
              <Form.Item label="" name="valido">
                <SelectAntd
                  disabled={!cargado}
                  size="large"
                  FilterProp="children"
                  showSearch
                  allowClear
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  listItemHeight={10}
                  listHeight={250}
                  options={[
                    {key: "validado", label: "Validado"},
                    {key: "noValidado", label: "No Validado"}
                  ]?.map((m, i) => ({
                    label: `${m.label}`,
                    value: m.key,
                    key: m.key,
                    item: m.key
                  }))}
                  rules={[{required: true, message: 'Por favor seleccione una fracción'}]}
                  placeholder="Selecciona un periodo"
                />
              </Form.Item>
            </Col>
            <Col
              span={3}
            >
              <Form.Item label="" name="veredicto">
                <SelectAntd
                  size="large"
                  disabled={!cargado}
                  FilterProp="children"
                  showSearch
                  allowClear
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  listItemHeight={10}
                  listHeight={250}
                  options={[{key: "APROBADO", label: "Aprobado"}, {
                    key: "DESAPROBADO",
                    label: "No Aprobado"
                  }]?.map((m, i) => ({
                    label: `${m.label}`,
                    value: m.key,
                    key: m.key,
                    item: m.key
                  }))}
                  rules={[{required: true, message: 'Por favor seleccione una fracción'}]}
                  placeholder="Selecciona un periodo"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={4}>
          <Form.Item label="&nbsp;">
            <ButtonGroup
              data={btnGroup}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

Filtros.propTypes = {
  btnGroup: PropTypes.object,
  formFiltros: PropTypes.any.isRequired,
}

export default Filtros