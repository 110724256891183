import {React} from "react";
import {DefaultLayout} from "../../components/layouts";
import {useModel, useQuery} from "../../hooks"
import {Form, } from 'antd'
import {
  EditOutlined,
  FolderOpenOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import StatusResponse from "../../services/statusResponse";
import {ViewLoading} from "../../components";
import {Formulario} from "./form/Formulario";
import moment from "moment";

export const DocumentoAdministrativaDetalle = () => {
  const q = useQuery()
  const id = q.get("id")
  const editing = !!id
  const urlBase = '/documentacion/documentos-portal-administrativa/';
  const title = "Documentos Portal Administración"
  const breadcrumb = [
    {
      name: title,
      to: urlBase,
      icon: <FolderOpenOutlined/>
    },
    {
      name: editing ? "Editar" : "Agregar",
      to: editing ?
        `${urlBase}/detalle?id=${id}` :
        `${urlBase}/nueva`,
      icon: editing ? <EditOutlined/> : <PlusCircleOutlined/>
    }
  ];

  const {
    model,
    modelLoading,
  } = useModel({
    name: "documento-portal",
    id: id,
    expand: 'categoria, categoria.subCategorias'
  })


  if (modelLoading) return <ViewLoading/>

  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title={editing ?
        `Editando Documento: ${model?.nombre}` :
        "Nuevo Documento "}
      children={
        <Formulario
          id={id}
          model={model}
          urlBase={urlBase}
          titulo={title}
        />
      }
    />
  );
};