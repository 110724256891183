import React, {useState} from "react";
import {Button, DatePicker, Form, notification, Space, Table, Tag, Tooltip, Row, Col, Card, Statistic} from "antd";
import {
  AuditOutlined,
  EyeOutlined,
  FilePdfOutlined,
  SearchOutlined,
  ClearOutlined,
  FileExcelOutlined,
  LikeOutlined,
  DislikeOutlined,
  DownloadOutlined,
  EyeInvisibleOutlined
} from "@ant-design/icons";
import SimpleTableLayout from "../../components/layouts/SimpleTableLayout";
import {useModels, useApp} from "../../hooks"
import {useHistory, useLocation} from "react-router-dom";
import moment from "moment"
import Filtros from "./Filtros";
import StatusResponse from "../../services/statusResponse";
import {Respuesta} from "../../utilities/Respuesta";
import TablaComponent from "../../components/TablaComponent";

const Title = "Recursos de Revisión";
const emptyText = "No hay recursos registrados...";

const breadcrumbItems = [
  {
    name: "Revisión de Obligaciones",
    to: "revision-obligaciones",
    icon: <AuditOutlined/>
  }
];

export const RevisionObligacionesLista = () => {
  const history = useHistory();
  const [formFiltros] = Form.useForm();
  const ordenar = 'id-desc';
  const {token} = useApp();
  const {REACT_APP_API_URL: baseUrl} = process.env;
  const location = useLocation();
  let tablaRef = React.useRef(null);
  const endPoint = 'transparencia/revision';

  const [cargado, setCargado] = useState(true);
  const [buscarParams, setBuscarParams] = useState({});

  const requestParamsDashboard = React.useMemo(() => (
    {
      name: 'transparencia/dashboard-revision',
      ordenar: ordenar,
      expand: "articulo,articulo.padre,ente,usuarioValido",
      extraParams: {
        ...buscarParams
      }
    }), [buscarParams])

  const btnGroup = [
    {
      id: 1,
      onClick: () => {
        setBuscarParams({});
        onSearch();
      },
      props: {disabled: false, type: "default", block: true},
      icon: <SearchOutlined/>,
    },
    {
      id: 2,
      onClick: () => {
        setBuscarParams({});
        formFiltros.resetFields();
      },
      props: {disabled: false, type: "dashed", block: true},
      icon: <ClearOutlined/>,
    },
    {
      id: 4,
      onClick: () => cargado ? onImprimirExcel() : onImprimirExcelFaltantes(),
      props: {disabled: false, type: "primary", block: true},
      icon: <FileExcelOutlined/>,
    },
    {
      id: 3,
      onClick: () => onImprimirPdf(),
      hidden: cargado,
      props: {disabled: false, type: "danger", block: true},
      icon: <FilePdfOutlined/>,
    },
  ];

  const [
    dashboard, , , ,
  ] = useModels(requestParamsDashboard)

  console.log(dashboard)

  const columns = [
    {
      title: "Veredicto",
      dataIndex: "calificacion",
      key: "calificacion",
      width: 120,
      render: (prop, record) => record?.validado ? record?.calificacion === "APROBADO" ?
          <Tag color="#87d068"
               style={{fontSize: "15px", width: "100%", textAlign: "center", padding: "5px 0"}}>Aprobado</Tag> :
          <Tag color="#f50" style={{fontSize: "15px", width: "100%", textAlign: "center", padding: "5px 0"}}>No
            Aprobado</Tag> :
        "---"
    },
    {
      title: "Fecha Creado",
      dataIndex: "creado",
      ellipsis: true,
      key: "creado",
      width: 170,
      render: (prop, record) => moment(record?.creado).parseZone().local().format("DD/MM/YYYY HH:mm")
    },
    {
      title: "Ente",
      dataIndex: "ente",
      ellipsis: true,
      key: "ente",
      width: 200,
      render: (prop, record) => record?.ente?.titulo
    },
    {
      title: "Trimestre",
      dataIndex: "trimestre",
      key: "trimestre",
      width: 150,
    },
    {
      title: "Año",
      dataIndex: "ano",
      key: "ano",
      width: 90,
    },
    {
      title: "Artículo",
      dataIndex: "idArticulo",
      ellipsis: true,
      key: "idArticulo",
      width: 150,
      render: (prop, record) => record?.articulo?.padre?.titulo || record?.articulo
    },
    {
      title: "Fracción",
      dataIndex: "idFraccion",
      ellipsis: true,
      key: "idFraccion",
      width: 300,
      render: (prop, record) => record?.articulo?.titulo || record?.fraccion
    },
    {
      title: "Título",
      dataIndex: "titulo",
      ellipsis: true,
      key: "titulo",
      width: 300,
    },
    {
      title: "Validado",
      dataIndex: "validado",
      key: "validado",
      ellipsis: true,
      width: 200,
      render: (prop, record) => record?.validado ?
        <>{moment(record?.validado).format("DD/MM/YYYY HH:mm")} por {record?.usuarioValido?.nombre}</> :
        "---"
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 170,
      render: (_, record) => (
        <Space justify="space-between">
          <Button
            type="default"
            shape="circle"
            disabled={!cargado}
            icon={<EyeOutlined/>}
            onClick={() => history.push(`revision-obligaciones/detalle?id=${record?.id}`)}
          />
          <Button
            type="primary"
            shape="circle"
            disabled={!cargado}
            icon={<LikeOutlined/>}
            onClick={() => aprobarRecurso(record?.id, "APROBADO")}
          />
          <Button
            type="danger"
            shape="circle"
            disabled={!cargado}
            icon={<DislikeOutlined/>}
            onClick={() => aprobarRecurso(record?.id, "DESAPROBADO")}
          />
          <Button
            type=""
            shape="circle"
            disabled={!cargado}
            icon={<DownloadOutlined/>}
            onClick={() => descrgarArchivo(record?.id)}
          />
        </Space>
      ),
    },
  ];

  const aprobarRecurso = async (id, tipo) => {
    let body = {
      id: id,
      tipo: tipo
    }

    try {
      const res2 = await StatusResponse.post("transparencia/aprobar", body)
      if (Respuesta(res2))
        console.log("aprobado")
    } catch (error) {
      console.log(error)
    } finally {
    }
  }

  const descrgarArchivo = (id) => {
    window.open(`${baseUrl.replace("/v1/", "")}/publico/descarga/transparencia?id=${id}&p=tri`, '_blank')
  }

  const onSearch = () => {
    const values = formFiltros.getFieldsValue();
    let params = {};


    if (values.idEnte) {
      params.idEnte = values.idEnte
    }

    if (values.idArticulo) {
      params.idArticulo = values.idArticulo
    }

    if (values.idFraccion) {
      params.idFraccion = values.idFraccion
    }

    if (values.trimestre) {
      params.trimestre = values.trimestre
    }

    if (values.anio) {
      params.anio = values.anio.format('YYYY')
    }

    if (values.rango) {
      params.inicio = moment(values.rango[0]).format('YYYY-MM-DD');
      params.fin = moment(values.rango[1]).format('YYYY-MM-DD');
    }

    if (values.programa) {
      params.programa = values.programa
    }

    if (values.veredicto)
      params.veredicto = values.veredicto

    if (values.valido)
      params.valido = values.valido

    params.cargado = cargado

    setBuscarParams(params);
  };

  const onImprimirExcel = () => {
    const values = formFiltros.getFieldsValue();
    let busqueda = "";
    let error = "";
    let obligatorio = 0;

    if (values.idEnte) {
      busqueda += "idEnte=" + values.idEnte + "&"
    } else {
      error += ' Ente,';
      obligatorio++;
    }

    if (values.idArticulo) {
      busqueda += "idArticulo=" + values.idArticulo + "&"
    }

    if (values.idFraccion) {
      busqueda += "idFraccion=" + values.idFraccion + "&"
    }

    if (values.trimestre) {
      busqueda += "trimestre=" + values.trimestre + "&"
    } else {
      error += ' Trimestre,';
      obligatorio++;
    }

    if (values.anio) {
      busqueda += "anio=" + moment(values.anio).format('YYYY') + "&"
    } else {
      error += ' Año,';
      obligatorio++;
    }

    if (values.rango) {
      busqueda += "inicio=" + moment(values.rango[0]).format('YYYY-MM-DD') + ' 00:00:00' + "&";
      busqueda += "fin=" + moment(values.rango[1]).format('YYYY-MM-DD') + " 23:59:59" + "&";
    }

    if (values.veredicto)
      busqueda += "veredicto=" + buscarParams.veredicto + "&";

    if (values.valido)
      busqueda += "valido=" + buscarParams.valido + "&";

    busqueda += "cargados=" + cargado + "&";


    if (obligatorio > 0) {
      notification.error({
        message: "Atención",
        description: <>Seleccione una {error} para continuar </>,
        placement: 'bottomRight'
      });
      return;
    }

    window.open(`${baseUrl.replace("/v1/", "")}/reportes/excel-revision?${busqueda}access-token=${token}&r=1`, '_blank')
  };

  const onImprimirExcelFaltantes = () => {
    const values = formFiltros.getFieldsValue();
    let busqueda = "";
    let error = "";

    if (values.idEnte) {
      busqueda += "idEnte=" + values.idEnte + "&"
    } else
      error += "Ente, "

    if (values.trimestre) {
      busqueda += "trimestre=" + values.trimestre + "&"
    } else
      error += "Trimestre, "

    if (values.anio) {
      busqueda += "anio=" + moment(values.anio).format('YYYY') + "&"
    } else
      error += "Año, "


    if (busqueda === "") {
      notification.error({
        message: "Atención",
        description: <>Seleccione un {error} para continuar</>,
        placement: 'bottomRight'
      });
      return;
    }

    window.open(`${baseUrl.replace("/v1/", "")}/reportes/excel-revision-faltantes?${busqueda}access-token=${token}&r=1`, '_blank')
  };

  const onImprimirPdf = () => {
    const values = formFiltros.getFieldsValue();
    let busqueda = "";
    let error = "";

    if (values.idEnte) {
      busqueda += "idEnte=" + values.idEnte + "&"
    } else
      error += "Ente, "

    if (values.trimestre) {
      busqueda += "trimestre=" + values.trimestre + "&"
    } else
      error += "Trimestre, "

    if (values.anio) {
      busqueda += "anio=" + moment(values.anio).format('YYYY') + "&"
    } else
      error += "Año, "


    if (busqueda === "") {
      notification.error({
        message: "Atención",
        description: <>Seleccione un {error} para continuar</>,
        placement: 'bottomRight'
      });
      return;
    }

    window.open(`${baseUrl.replace("/v1/", "")}/reportes/pdf-revision-faltantes?${busqueda}access-token=${token}&r=1`, '_blank')
  };


  React.useEffect(() => {
    if (location) {
      console.log(localStorage.getItem("formulario"))
    }
  }, [location])

  React.useEffect(() => {
    onSearch();
  }, [cargado])

  return (
    <SimpleTableLayout
      title={Title}
      breadcrumbItems={breadcrumbItems}
      withSearchButton={false}
      children={<>
        <Row>
          <Filtros
            formFiltros={formFiltros}
            btnGroup={{
              btnGroup,
              flex: {justifyContent: "start", flexDirection: "row"},
            }}
            cargado={cargado}
            setCargado={setCargado}
          />
        </Row>
        <Row
          gutter={3}
        >
          <Col span={5}>
            <Card>
              <Statistic
                title="Validados"
                valueStyle={{
                  color: "#03a9f4",
                }}
                value={dashboard[0]?.validados}
                prefix={<EyeOutlined/>}
              />
            </Card>
          </Col>
          <Col span={5}>
            <Card>
              <Statistic
                title="Por Validar"
                valueStyle={{
                  color: "#03a9f4",
                }}
                value={dashboard[0]?.porValidar}
                prefix={<EyeInvisibleOutlined/>}
              />
            </Card>
          </Col>
          <Col span={5}>
            <Card>
              <Statistic
                title="Aprobados"
                valueStyle={{
                  color: "#ffc107",
                }}
                value={dashboard[0]?.aprobados}
                prefix={<LikeOutlined/>}
              />
            </Card>
          </Col>
          <Col span={5}>
            <Card>
              <Statistic
                title="No Aprobados"
                valueStyle={{
                  color: "#ffc107",
                }}
                value={dashboard[0]?.noAprobados}
                prefix={<DislikeOutlined/>}
              />
            </Card>
          </Col>
          <Col span={4}>
            <Card
            >
              <Statistic
                title="Total"
                valueStyle={{
                  color: "#cf1322",
                }}
                value={dashboard[0]?.total}
              />
            </Card>
          </Col>
        </Row>
        <Row>
          <TablaComponent
            innerRef={tablaRef}
            nameURL={endPoint}
            columns={columns}
            style={{width: "100%"}}
            expand={"articulo,articulo.padre,ente,usuarioValido"}
            extraParams={buscarParams}
          />
        </Row>
      </>
      }
    />
  );
};