import React, {useEffect, useState} from 'react'
import {
  Row,
  Col,
  Form,
  Input,
  Modal,
  Button,
  Select,
  DatePicker, Image
} from 'antd'
import {DeleteOutlined, SaveOutlined} from '@ant-design/icons'
import StatusResponse from "../../../services/statusResponse";
import {Respuestas} from "../../../utilities/Respuesta";
import {useHistory} from "react-router-dom";
import {EditorTexto, Uploader, Select as SelectComponent, InputAutoComplete, ImageUploader} from "../../../components";
import HttpService from "../../../services/httpService";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";


export const Formulario = ({model = null, id, alTerminar, endPoint, url, idModelo, media, editando,}) => {

  const [form] = Form.useForm();
  const history = useHistory();

  const [guardando, setGuardando] = useState(false);

  const [listaImagenes, setListaImagenes] = useState([])
  const [referenciasImagenes, setReferenciasImagenes] = useState([])

  const [listaPdf, setListaPdf] = useState([])
  const [referenciasPdf, setReferenciasPdf] = useState([])

  const [idPublicacionTipo, setIdPublicacionTipo] = useState([])

  const onFinish = async (values) => {
    let _body = {...values};

    if (editando) {
      _body.id = id;
    }

    if (referenciasImagenes.length > 0)
      _body.imagenes = referenciasImagenes

    if (referenciasPdf.length > 0)
      _body.archivos = referenciasPdf

    try {
      setGuardando(true);
      const respuesta = await StatusResponse.post(`${endPoint}/guardar`, _body);
      if (Respuestas(respuesta)) {
        history.push(`${url}`)
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGuardando(false);
    }
  }

  const handleRemove = (file) => {
    Modal.confirm({
      title: '¿Desea eliminar esta imagen?',
      icon: <DeleteOutlined style={{color: 'red'}}/>,
      content: file.name,
      async onOk() {
        try {
          console.log()
          const res = await HttpService.delete('v1/media', {
            id: file.id,
          })

          Respuestas(res)

        } catch (error) {
          console.log('En handleRemove()', error)
        }
      },
      onCancel() {
        return false
      },
    })
    return false
  }

  useEffect(() => {
    if (model) {
      form.setFieldsValue({
        ...model,
        fechaPublicacion: moment(model?.fechaPublicacion)
      })
      if (model[media]) {

        console.log(model)

        setListaImagenes(model[media]
          .filter(item => item?.tipo === 'IMAGEN')
          .filter(item => item?.media !== null)
          .map(media => media.media))

        setReferenciasImagenes(model[media]
          .filter(item => item?.tipo === 'IMAGEN')
          .filter(item => item?.media !== null)
          .map(media => media.media))

        setListaPdf(model[media]
          .filter(item => item?.tipo === 'ARCHIVO')
          .filter(item => item?.media !== null)
          .map(media => media.media))

        setReferenciasPdf(model[media]
          .filter(item => item?.tipo === 'ARCHIVO')
          .filter(item => item?.media !== null)
          .map(media => media.media))
      }
    } else {
      form.resetFields()
    }
  }, [form, model])

  return (<Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
    >
      <Row gutter={[10, 20]}>
        <Col offset={12} xs={24} lg={12}>
          <Form.Item
            label="Fecha de Publicación"
            name="fechaPublicacion"
          >
            <DatePicker
              size={'large'}
              format={'DD/MM/YYYY'}
              style={{width: '100%'}}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[10, 20]}>
        <Col xs={24} lg={12}>
          <Form.Item
            label="Titulo"
            name="titulo"
            rules={[{
              required: true,
              message: 'Por favor ingresa un nombre'
            }]}
          >
            <Input
              size={'large'}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            label="Tipo de Publicación"
            name="idPublicacionTipo"
            rules={[{
              required: true,
              message: 'Por favor ingresa un nombre'
            }]}
          >
            <SelectComponent
              modelsParams={{
                name: 'publicacion-tipo',
                ordenar: 'titulo',
              }}
              size={'large'}
              placeholder="Selecciona un clave"
              labelProp="titulo"
              valueProp="id"
              allowClear={true}
              autoComplete="off"
              onChange={value => setIdPublicacionTipo(value)}
              render={(_, row) => `${row.titulo}`}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            label="Sub Tipo de Publicación"
            name="idPublicacionSubTipo"
          >
            <SelectComponent
              modelsParams={{
                name: 'publicacion-sub-tipo',
                ordenar: 'titulo',
              }}
              size={'large'}
              placeholder="Selecciona un clave"
              labelProp="titulo"
              valueProp="id"
              allowClear={true}
              autoComplete="off"
              extraParams={{
                idPublicacionTipo: idPublicacionTipo
              }}
              render={(_, row) => `${row.titulo}`}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            label="#Tag"
            name="tag"
          >
            <InputAutoComplete
              modelsParams={{
                name: 'publicacion-nota',
              }}
              size={'large'}
              campo={'tag'}
              labelProp="tag"
              allowClear={true}
              autoComplete="off"
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item
            label="Descripción"
            name="descripcion"
            rules={[{
              required: true,
              message: 'Por favor ingresa una descripción'
            }]}
          >
            <EditorTexto
              size={'large'}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item
            label="Extracto"
            name="contenido"
            rules={[{
              required: true,
              message: 'Por favor ingresa una descripción'
            }]}
          >
            <TextArea
              size={'large'}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item
            label="URL"
            name="url"
          >
            <Input
              size={'large'}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label="Imagen Destacada"
            name="idImagenDestacada"
          >
            <Select
              size={'large'}
              options={referenciasImagenes.map((img) => {
                return {
                  label: <><Image src={img.ruta} alt={`Imagen para la opción ${img.descripcion}`} width="40px"/>
                    <span>  {img.descripcion}</span></>,
                  value: img.id,
                }
              })}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={16}>
          <Form.Item label="Imágenes">
            <ImageUploader
              endPoint={'media/guardar'}
              listaArchivos={listaImagenes}
              setListaArchivos={setListaImagenes}
              setReferencias={setReferenciasImagenes}
              onRemove={(file) => handleRemove(file)}
              aspectWidth={190}
              aspectHeight={200}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item label="Archivos">
            <Uploader
              endPoint={'media/guardar'}
              listaArchivos={listaPdf}
              setListaArchivos={setListaPdf}
              setReferencias={setReferenciasPdf}
              onRemove={(file) => handleRemove(file)}
            />
          </Form.Item>
        </Col>
      </Row>
      <br/>
      <Row justify="end">
        <Col xs={24} lg={6}>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              block
              icon={<SaveOutlined/>}
              loading={guardando}
            >
              Guardar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
