import {React} from "react";
import {DefaultLayout} from "../../components/layouts";
import {useModel, useQuery} from "../../hooks"
import {Button, Descriptions, Upload} from 'antd'
import {
  AuditOutlined,
  EyeOutlined, FilePdfOutlined,
} from "@ant-design/icons";
import {ViewLoading} from "../../components";
import moment from "moment";

export const RecursoRevisionDetalle = () => {
  const q = useQuery()
  const id = q.get("id")
  const editing = !!id
  const breadcrumb = [
    {
      name: "Recursos de Revisión",
      to: "/mensajeria/recurso-revision",
      icon: <AuditOutlined/>
    },
    {
      name: editing && "Descripción",
      to: editing && `/mensajeria/recurso-revision/detalle?id=${id}`,
      icon: editing && <EyeOutlined/>
    }
  ];
  const {
    model,
    modelLoading,
  } = useModel({
    name: "recurso-revision",
    id: id,
  })

  if (modelLoading) return <ViewLoading/>

  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title={editing && `${model?.solicitante}`}
      children={<>

        <Button
          type="danger"
          title=""
          onClick={() => window.open(`https://intranet.api.transparenciasonora.org/reportes/recurso?id=${model.id}`, '_blank')}
        >
          <FilePdfOutlined/> Descargar PDF
        </Button>
        <Descriptions
          bordered
          column={{xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1}}
          size="small"
        >
          <Descriptions.Item label="Solicitante">{model?.solicitante}</Descriptions.Item>
          <Descriptions.Item label="Razón Social">{model?.razonSocial}</Descriptions.Item>
          <Descriptions.Item label="Representante">{model?.representante}</Descriptions.Item>
          <Descriptions.Item label="Tercer Interesado">{model?.interesado}</Descriptions.Item>
          <Descriptions.Item label="Correo" span={5}>{model?.correo}</Descriptions.Item>
          <Descriptions.Item label="Sujeto Obligado" span={5}>{model?.sujetoObligado}</Descriptions.Item>
          <Descriptions.Item label="Folio de Solicitud" span={5}>{model?.solicitudFolio}</Descriptions.Item>
          <Descriptions.Item label="Se Respondió" span={2}>{model?.respondio}</Descriptions.Item>
          <Descriptions.Item label="Fecha de respuesta o de solicitud"
                             span={3}> {model?.fecha ? moment(model?.fecha).format("DD/MM/YYYY") :
            <strong>No se introdujo "Fecha de respuesta o de solicitud",<br/>la fecha de creacion
              fue {moment(model?.creado).format("DD/MM/YYYY HH:mm:SS")} </strong>}
          </Descriptions.Item>
          <Descriptions.Item label="Acto que reclama" span={5}>{model?.queja}</Descriptions.Item>
          <Descriptions.Item label="Hechos o Agravios" span={5}>{model?.agravio}</Descriptions.Item>
          <Descriptions.Item label="Descripción de Pruebas" span={5}>{model?.descripcionPruebas}</Descriptions.Item>
          {
            model?.solicitud && (
              <Descriptions.Item label="Solicitud de Acceso">
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture"
                  defaultFileList={[
                    {
                      uid: '-1',
                      name: `Solicitud de Acceso`,
                      status: 'done',
                      url: `https://intranet.api.transparenciasonora.org/assets/recurso-revision/${model?.solicitud}`,
                    }
                  ]}
                  showUploadList={{
                    showDownloadIcon: false,
                    showRemoveIcon: false,
                  }}
                  style={{
                    marginBottom: '0px',
                  }}
                />
              </Descriptions.Item>
            )
          }
          {
            model?.prueba1 && (
              <Descriptions.Item label="Archivo de Prueba 1">
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture"
                  defaultFileList={[
                    {
                      uid: '-1',
                      name: `Archivo de Prueba 1`,
                      status: 'done',
                      url: `https://intranet.api.transparenciasonora.org/assets/recurso-revision/${model?.prueba1}`,
                    }
                  ]}
                  showUploadList={{
                    showDownloadIcon: false,
                    showRemoveIcon: false,
                  }}
                  style={{
                    marginBottom: '0px',
                  }}
                />
              </Descriptions.Item>
            )
          }
          {
            model?.prueba2 && (
              <Descriptions.Item label="Archivo de Prueba 2">
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture"
                  defaultFileList={[
                    {
                      uid: '-1',
                      name: `Archivo de Prueba 2`,
                      status: 'done',
                      url: `https://intranet.api.transparenciasonora.org/assets/recurso-revision/${model?.prueba2}`,
                    }
                  ]}
                  showUploadList={{
                    showDownloadIcon: false,
                    showRemoveIcon: false,
                  }}
                  style={{
                    marginBottom: '0px',
                  }}
                />
              </Descriptions.Item>
            )
          }
          {
            model?.prueba3 && (
              <Descriptions.Item label="Archivo de Prueba 3">
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture"
                  defaultFileList={[
                    {
                      uid: '-1',
                      name: `Archivo de Prueba 3`,
                      status: 'done',
                      url: `https://intranet.api.transparenciasonora.org/assets/recurso-revision/${model?.prueba3}`,
                    }
                  ]}
                  showUploadList={{
                    showDownloadIcon: false,
                    showRemoveIcon: false,
                  }}
                  style={{
                    marginBottom: '0px',
                  }}
                />
              </Descriptions.Item>
            )
          }
        </Descriptions>
      </>
      }
    />


  );
};