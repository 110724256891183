import React, { useMemo } from "react";
import { Button, Space, Tooltip } from "antd";
import {AuditOutlined, EyeOutlined, FilePdfOutlined, MailOutlined} from "@ant-design/icons";
import SimpleTableLayout from "../../components/layouts/SimpleTableLayout";
import { useHistory } from "react-router-dom";
import moment from "moment"
import TablaComponent from "../../components/TablaComponent";

const viewTitle = "Buzon";
const searchPlaceholder = "Buscar...";
const emptyText = "No hay Buzons registrados...";

const breadcrumbItems = [
  {
    name: "Buzón",
    to: "Buzon",
    icon: <AuditOutlined />
  }
];

export const BuzonLista = () => {
  const history = useHistory();
  const [search, setSearch] = React.useState("")
  const [searchLoading, setSearchLoading] = React.useState(false)

  const sinDefinir = <i>-Sin definir-</i>

  const renderSimple = (item, record) => {
    return(<a onClick={() => history.push(`buzon/detalle?id=${record?.id}`)}> {item} </a>);
  }

  const renderEstatus = (item, record) => {
    if (record.visto === null){
      return <a onClick={() => history.push(`buzon/detalle?id=${record?.id}`)}>
        <MailOutlined style={{fontSize:"20px"}}/> Nuevo
      </a>;
    }
    else{
      return <a onClick={() => history.push(`buzon/detalle?id=${record?.id}`)}>
        {moment.utc(record.visto).local().format("DD/MM/YYYY HH:mm")}
      </a>;
    }
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      width: 100,
    },
    {
      title: "visto",
      dataIndex: "estatus",
      key: "estatus",
      width: 100,
      render: renderEstatus
    },
    {
      title: "Creado",
      dataIndex: "creado",
      key: "creado",
      fixed: "left",
      width: 100,
      render: prop => prop ? moment(prop).format("DD/MM/YYYY") : sinDefinir
    },
    {
      title: "Correo ",
      dataIndex: "correo",
      key: "correo",
      fixed: "left",
      width: 200,
      render: renderSimple
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      fixed: "left",
      width: 200,
      render: renderSimple
    },
    {
      title: "Remitente",
      dataIndex: "remitente",
      key: "remitente",
      fixed: "left",
      width: 200,
      render: renderSimple
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Space justify="space-between">
          <Tooltip title="Ver detalles">
            <Button
              type="primary"
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() => history.push(`buzon/detalle?id=${record?.id}`)}
            />
          </Tooltip>
          <Tooltip title="Ver detalles">
            <Button
              type="danger"
              shape="circle"
              icon={<FilePdfOutlined />}
              onClick={() => window.open(`https://intranet.api.transparenciasonora.org/reportes/buzon?id=${record?.id}`, '_blank')}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const onSearch = React.useCallback(async (v) => {
    setSearchLoading(true);
    setSearch(v);
    setTimeout(() => setSearchLoading(false), 1500);
  },[]);


  const extraParams = useMemo(() => {
    return {q: search}
  }, [search]);

  return (
    <SimpleTableLayout
      breadcrumbItems={breadcrumbItems}
      title={viewTitle}
      searchPlaceholder={searchPlaceholder}
      searchLoading={searchLoading}
      onSearchClicked={onSearch}
      emptyText={emptyText}
    >
      <TablaComponent
        nameURL={'buzon'}
        columns={columns}
        order={'id-desc'}
        extraParams={extraParams}
      />
    </SimpleTableLayout>
  );
};
