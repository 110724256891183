import {Listado} from "./Listado";
import {Detalle} from "./Detalle";
import {Link} from "react-router-dom";

const titulo = "Directorio ISTAI";
const endPoint = 'directorio'
const url = '/directorio'
const expand = 'mediaDirectorio'
const orden = 'orden'
const idModelo = 'id'

const defaultText = (item, row) =>
  <Link to={`${url}/detalle?id=${row[idModelo]}`} style={{color: 'black'}}>
    {item}
  </Link>

const columnas = [
  {
    title: '',
    index: 'orden',
    key: 'orden',
    dataIndex: 'orden',
    ellipsis: true,
    width: 80,
    render: defaultText
  },
  {
    title: 'Nombre',
    index: 'nombre',
    key: 'nombre',
    dataIndex: 'nombre',
    ellipsis: true,
    render: defaultText
  },
  {
    title: 'Correo',
    index: 'correo',
    key: 'correo',
    dataIndex: 'correo',
    ellipsis: true,
    render: defaultText
  },
  {
    title: 'Teléfono',
    index: 'telefono',
    key: 'telefono',
    dataIndex: 'telefono',
    ellipsis: true,
    render: defaultText
  },
]

const Directorio = () => (<Listado
  viewTitle={titulo}
  endPoint={endPoint}
  expand={expand}
  url={url}
  orden={orden}
  columnas={columnas}
  idModelo={idModelo}
/>)

const DirectorioDetalle = () => (<Detalle
  viewTitle={titulo}
  endPoint={endPoint}
  expand={expand}
  url={url}
  orden={orden}
  idModelo={idModelo}
/>)

export {Directorio, DirectorioDetalle}
