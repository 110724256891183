import React from 'react'
import {Button, Col, DatePicker, Form, Input, Row, Space, Tooltip} from 'antd'
import {ClearOutlined, PlusOutlined, SearchOutlined} from '@ant-design/icons'
import {useHistory} from "react-router-dom";

const {RangePicker} = DatePicker

export const Search = ({
                         disabledButtons,
                         form,
                         loading,
                         onClean,
                         onSearch,
                         botonNuevo
                       }) => {
  const history = useHistory()
  return (
    <Form
      form={form}
      layout='vertical'
    >
      <Row gutter={[16, 0]}>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
          <Form.Item
            label="Rango"
            name="rango"
          >
            <RangePicker
              format="DD/MM/YYYY"
              placeholder={['Fecha inicio', 'Fecha fin']}
              style={{width: '100%'}}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
          <Form.Item
            label="Buscar"
            name="buscar"
          >
            <Input placeholder="Buscar por Cuenta, Padrón, Autorización, Cultivo, etc..."/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
          <Space
            justify="space-between"
            style={{marginTop: '.5rem'}}
          >
            <Tooltip title="Buscar">
              <Button
                disabled={disabledButtons}
                icon={<SearchOutlined/>}
                loading={loading}
                onClick={onSearch}
                shape="circle"
                style={{marginTop: '1.5rem'}}
                type="primary"
              />
            </Tooltip>
            <Tooltip title="Limpiar">
              <Button
                disabled={disabledButtons}
                icon={<ClearOutlined/>}
                onClick={onClean}
                shape="circle"
                style={{marginTop: '1.5rem'}}
                type="default"
              />
            </Tooltip>
          </Space>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Button
            disabled={disabledButtons}
            icon={<PlusOutlined/>}
            loading={loading}
            onClick={()=>history.push(botonNuevo)}
            style={{marginTop: '1.5rem'}}
            type="primary"
          >
            Agregar
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
