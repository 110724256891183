import { React } from "react";
import { DefaultLayout } from "../../components/layouts";
import { useModel, useQuery } from "../../hooks"
import {Button, Descriptions, Upload} from 'antd'
import {
  AuditOutlined,
  EyeOutlined, FilePdfOutlined,
} from "@ant-design/icons";
import { ViewLoading } from "../../components";

export const DenunciaDetalle = () => {
  const q = useQuery()
  const id = q.get("id")
  const editing = !!id
  const breadcrumb = [
    {
      name: "Denuncias",
      to: "/mensajeria/denuncia",
      icon: <AuditOutlined />
    },
    {
      name: editing && "Denuncia",
      to: editing && `/mensajeria/denuncia/detalle?id=${id}`,
      icon: editing && <EyeOutlined/>
    }
  ];
  const {
    model,
    modelLoading,
  } = useModel({
    name: "denuncia",
    id: id,
  })

  if (modelLoading) return <ViewLoading/>

  console.log(model)

  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title={editing && `Denuncia: ${model?.correoDenunciante}`}
      children={<>

        <Button
          type="danger"
          title=""
          onClick={() => window.open(`https://intranet.api.transparenciasonora.org/reportes/denuncia?id=${model.id}`, '_blank')}
        >
          <FilePdfOutlined/> Descargar PDF
        </Button>
        <Descriptions
          bordered
          column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          size="small"
        >
          <Descriptions.Item label="Sujeto Obligado a Denunciar:"  span={5}>{model?.sujetoDenunciar}</Descriptions.Item>
          <Descriptions.Item label="Descripción clara y precisa del incumplimiento."  span={5}>{model?.descripcionClara}</Descriptions.Item>
          {
            model?.solicitud && (
              <Descriptions.Item label="Documentos adjuntos" span={5}>
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture"
                  defaultFileList={[
                    {
                      uid: '-1',
                      name: `Prueba`,
                      status: 'done',
                      url: `https://intranet.api.transparenciasonora.org/assets/denuncia/${model?.solicitud}`,
                    }
                  ]}
                  showUploadList={{
                    showDownloadIcon: false,
                    showRemoveIcon: false,
                  }}
                  style={{
                    marginBottom: '0px',
                  }}
                />
              </Descriptions.Item>
            )
          }
          <Descriptions.Item label="Nombre del denunciante:"  span={2}>{model?.nombreDenunciante}</Descriptions.Item>
          <Descriptions.Item label="Perfil:"  span={2}>{model?.perfil}</Descriptions.Item>
          <Descriptions.Item label="Correo Electrónico:" span={5}> {model?.correoDenunciante}</Descriptions.Item>
        </Descriptions>
        </>
      }
    />
  );
};