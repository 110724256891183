import {Listado} from "./Listado";
import {Detalle} from "./Detalle";
import {Link} from "react-router-dom";

const titulo = "Publicaciones";
const endPoint = 'publicacion-nota'
const url = '/publicacion-nota'
const expand = 'imagenDestacada,mediaPublicacionNota,media,publicacionTipo,publicacionSubTipo'
const orden = 'id'
const idModelo = 'id'
const media = 'mediaPublicacionNota'

const columnas = [
  {
    title: 'Publicacion Tipo',
    index: 'publicacionTipo',
    key: 'publicacionTipo',
    dataIndex: 'publicacionTipo',
    ellipsis: true,
    render: (row, item) =>
      <Link to={`${url}/detalle?id=${item[idModelo]}`} style={{color: 'black'}}>
        {row?.titulo}
      </Link>
  },
  {
    title: 'Publicacion Sub Tipo',
    index: 'publicacionSubTipo',
    key: 'publicacionSubTipo',
    dataIndex: 'publicacionSubTipo',
    ellipsis: true,
    render: (item, row) =>
      <Link to={`${url}/detalle?id=${row[idModelo]}`} style={{color: 'black'}}>
        {item?.titulo}
      </Link>
  },
  {
    title: 'Titulo',
    index: 'titulo',
    key: 'titulo',
    dataIndex: 'titulo',
    ellipsis: true,
    render: (row, item) =>
      <Link to={`${url}/detalle?id=${item[idModelo]}`} style={{color: 'black'}}>
        {row}
      </Link>
  },
  {
    title: 'Extracto',
    index: 'contenido',
    key: 'contenido',
    dataIndex: 'contenido',
    ellipsis: true,
    render: (row, item) =>
      <Link to={`${url}/detalle?id=${item[idModelo]}`} style={{color: 'black'}}>
        {row}
      </Link>
  }
]

const PublicacionNota = () => (<Listado
  viewTitle={titulo}
  endPoint={endPoint}
  expand={expand}
  url={url}
  orden={orden}
  columnas={columnas}
  idModelo={idModelo}
/>)

const PublicacionNotaDetalle = () => (<Detalle
  viewTitle={titulo}
  endPoint={endPoint}
  expand={expand}
  url={url}
  orden={orden}
  idModelo={idModelo}
  media={media}
/>)

export {PublicacionNota, PublicacionNotaDetalle}
