import {React} from "react";
import {DefaultLayout} from "../../components/layouts";
import {useModel, useQuery} from "../../hooks"
import {Button, Descriptions, Upload} from 'antd'
import {
  AuditOutlined,
  EyeOutlined, FilePdfOutlined,
} from "@ant-design/icons";
import {ViewLoading} from "../../components";

export const BuzonDetalle = () => {
  const q = useQuery()
  const id = q.get("id")
  const editing = !!id
  const breadcrumb = [
    {
      name: "Buzón",
      to: "/buzon",
      icon: <AuditOutlined/>
    },
    {
      name: editing && "Mensaje",
      to: editing && `/mensajeria/buzon/detalle?id=${id}`,
      icon: editing && <EyeOutlined/>
    }
  ];
  const {
    model,
    modelLoading,
  } = useModel({
    name: "buzon",
    id: id,
  })

  if (modelLoading) return <ViewLoading/>

  console.log(model)

  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title={editing && `Mensaje: ${model?.correo}`}
      children={<>

        <Button
          type="danger"
          title=""
          onClick={() => window.open(`https://intranet.api.transparenciasonora.org/reportes/buzon?id=${model.id}`, '_blank')}
        >
          <FilePdfOutlined/> Descargar PDF
        </Button>
        <Descriptions
          bordered
          column={{xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1}}
          size="small"
        >
          <Descriptions.Item label="Quien envía:" span={5}>{model?.remitente}</Descriptions.Item>
          <Descriptions.Item label="Descripción" span={5}>{model?.descripcion}</Descriptions.Item>
          {
            model?.solicitud && (
              <Descriptions.Item label="Documentos adjuntos" span={5}>
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture"
                  defaultFileList={[
                    {
                      uid: '-1',
                      name: `Prueba`,
                      status: 'done',
                      url: `https://intranet.api.transparenciasonora.org/assets/buzon/${model?.archivo}`,
                    }
                  ]}
                  showUploadList={{
                    showDownloadIcon: false,
                    showRemoveIcon: false,
                  }}
                  style={{
                    marginBottom: '0px',
                  }}
                />
              </Descriptions.Item>
            )
          }
          <Descriptions.Item label="Nombre:" span={2}>{model?.nombre}</Descriptions.Item>
          <Descriptions.Item label="Perfil:" span={2}>{model?.perfil}</Descriptions.Item>
          <Descriptions.Item label="Correo Electrónico:" span={5}> {model?.correo}</Descriptions.Item>
          {
            model?.archivo && (
              <Descriptions.Item label="Solicitud de Acceso">
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture"
                  defaultFileList={[
                    {
                      uid: '-1',
                      name: `Solicitud de Acceso`,
                      status: 'done',
                      url: `https://intranet.api.transparenciasonora.org/assets/buzon/${model?.archivo}`,
                    }
                  ]}
                  showUploadList={{
                    showDownloadIcon: false,
                    showRemoveIcon: false,
                  }}
                  style={{
                    marginBottom: '0px',
                  }}
                />
              </Descriptions.Item>
            )
          }
        </Descriptions>
      </>
      }
    />
  );
};