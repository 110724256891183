import React, {useEffect, useState} from "react";
import {DefaultLayout} from "../../components/layouts";
import {Button, Col, Comment, Descriptions, Row, message, Tooltip, Divider} from 'antd'
import {
  AuditOutlined,
  EyeOutlined,
  DownloadOutlined,
  LikeOutlined,
  DislikeOutlined, ArrowLeftOutlined,
} from "@ant-design/icons";
import {ViewLoading} from "../../components";
import moment from "moment";
import StatusResponse from "../../services/statusResponse";
import {useAuth, useModel, useQuery} from "../../hooks";
import {useHistory} from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import {List} from "antd/es";
import {Respuesta} from "../../utilities/Respuesta";
import Title from "antd/lib/typography/Title";

const {Item} = Descriptions;

export const RevisionObligacionesDetalle = () => {
  const q = useQuery()
  const id = q.get("id")
  const editing = !!id
  const {user} = useAuth();
  const history = useHistory();
  const [comentario, setComentario] = useState("");
  const [comentarioList, setComentarioList] = useState([]);
  const [loading, setLoading] = useState(false)
  const breadcrumb = [
    {
      name: "Revisión",
      to: "/revision-obligaciones",
      icon: <AuditOutlined/>
    },
    {
      name: editing && "Descripción",
      to: editing && `/revision-obligaciones/detalle?id=${id}`,
      icon: editing && <EyeOutlined/>
    }
  ];

  const {
    model, modelLoading, modelError, refreshModel
  } = useModel({
    name: "transparencia/revision",
    expand: "articulo,articulo.padre, ente, usuarioValido, comentarios, comentarios.usuario",
    id: id,
  })
  //
  // const eliminarComentario = async (idComentario) => {
  //   let body = {};
  //   body.idComentario = idComentario;
  //   try {
  //     setLoading(true)
  //     const res = await StatusResponse.post('atencion-ciudadana/eliminar-comentario', body);
  //     if (res) {
  //       //Si estatus 400 y "errores" es diferente a nulo
  //       if (res?.status === 400 && res?.errores !== null) {
  //         const arrayError = Object.values(res?.errores);
  //         console.log(arrayError);
  //         Modal.error({
  //           title: 'Error al guardar: datos incompletos.',
  //         });
  //         //cuando el dato ya existe no se puede guardar en BD
  //       } else if (res?.status === 400 && res?.errores === null) {
  //         message.error({
  //           content: res?.mensaje,
  //           style: {marginTop: '20vh'},
  //         });
  //         //todo salió bien
  //       } else if (res?.status === 200) {
  //         message.success({
  //           content: res?.mensaje,
  //           style: {marginTop: '20vh'},
  //         });
  //
  //         setComentarios(res?.response?.resultado);
  //         const arrComentarios = [...comentarioList];
  //
  //         let propietario = user?.idUsuario === res?.response?.detalle?.idUsuario;
  //         arrComentarios.push({
  //           actions: [propietario ? <Tooltip title="Eliminar comentario"
  //                                            onClick={() => eliminarComentario(res?.response?.detalle?.idComentario)}>
  //             <span><DeleteOutlined style={{color: "darkRed"}}/> </span>
  //           </Tooltip> : null],
  //           author: user?.nombreCompleto,
  //           avatar: user?.urlFoto,
  //           content: (
  //             <p>
  //               {comentario}
  //             </p>
  //           ),
  //           datetime: (
  //             <Tooltip title={moment(res?.response?.detalle?.creado).format('DD/MM/YYYY HH:mm:ss')}>
  //               <span>{moment(res?.response?.detalle?.creado).format('DD/MM/YYYY HH:mm:ss')}</span>
  //             </Tooltip>
  //           ),
  //         });
  //         setComentarioList(arrComentarios);
  //       }
  //     }
  //   } catch (e) {
  //     console.log('Error al guardar: ', e);
  //   } finally {
  //     setLoading(false)
  //   }
  // }
  //
  const comentar = async () => {
    let body = {};
    setComentario('');

    if (id)
      body.idTransparencia = id;

    body.comentario = comentario;
    body.idUsuario = user?.id;

    if (body.comentario === '') {
      message.error({
        content: "Comentario no puede estar vacio",
        style: {marginTop: '20vh'},
      });
      return;
    }

    try {
      setLoading(true)
      const res = await StatusResponse.post('transparencia/guardar-comentario', body);

      if (!Respuesta(res))
        return;

      const arrComentarios = [...comentarioList];

      arrComentarios.push({
        actions: [<span key="comment-list-reply-to-0">Eliminar</span>],
        author: res?.response?.detalle?.usuario?.nombre,
        content: (
          <p>
            {res?.response?.detalle?.comentario}
          </p>
        ),
        datetime: (
          <Tooltip title={moment(res?.response?.detalle?.creado).format('DD/MM/YYYY HH:mm:ss')}>
            <span style={{
              fontSize: '9px',
              fontWeight: 'bolder'
            }}>{moment(res?.response?.detalle?.creado).format('DD/MM/YYYY HH:mm:ss')}</span>
          </Tooltip>
        ),
      });
      setComentarioList(arrComentarios);

    } catch (e) {
      console.log('Error al guardar: ', e);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const arrComentarios = [...comentarioList];
    let _comentarios = model?.comentarios;

    _comentarios?.map((comentario) => {
      arrComentarios.push({
        actions: [null],
        author: <span style={{fontSize: '9px', fontWeight: 'bolder'}}>{comentario?.usuario?.nombre}</span>,
        content: (
          <p>
            {comentario?.comentario}
          </p>
        ),
        datetime: (
          <Tooltip title={moment(comentario?.creado).format('DD/MM/YYYY HH:mm:ss')}>
            <span style={{
              fontSize: '9px',
              fontWeight: 'bolder'
            }}>{moment(comentario?.creado).format('DD/MM/YYYY HH:mm:ss')}</span>
          </Tooltip>
        ),
      });
    })

    setComentarioList(arrComentarios);

  }, [model]);

  const aprobarRecurso = async (id, tipo) => {
    let body = {
      id: id,
      tipo: tipo
    }

    try {
      const res2 = await StatusResponse.post("transparencia/aprobar", body)
      if (Respuesta(res2))
        refreshModel();
    } catch (error) {
      console.log(error)
    }
  }

  if (modelLoading) return <ViewLoading/>

  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title={`${model?.titulo}`}
      children={<>
        <Row gutter={10}>
          <Col span={24} style={{textAlign: "right"}}>
            <Button
              type="default"
              title=""
              size='large'
              onClick={() => window.open(`${model.ruta}`, '_blank')}
            >
              <DownloadOutlined/> Descargar Archivo
            </Button>
          </Col>
          <Col span={24}>
            <Descriptions
              bordered
              column={{xl: 3, lg: 3, md: 3, sm: 2, xs: 1}}
              size="small"
            >
              <Item label="Ente" span={3}>{model?.ente?.titulo}</Item>
              <Item label="Fecha de Creación" span={2}>{model?.creado}</Item>
              <Item label="Título" span={2}>{model?.titulo}</Item>
              <Item label="Descripción" span={2}>{model?.descripcion}</Item>
              <Item label="Año" span={2}>{model?.ano}</Item>
              <Item label="Trimestre" span={2}>{model?.trimestre}</Item>
              <Item label="Artículo" span={2}>{model?.articulo?.padre?.titulo}</Item>
              <Item label="Fracción" span={2}>{model?.articulo?.titulo}</Item>
              <Item label="Validado" span={2}>{
                model?.validado ? moment(model?.validado).format("DD/MM/YYYY HH:mm") + ` por ${model?.usuarioValido?.nombre}` : "Sin Validar"
              }</Item>
            </Descriptions>
          </Col>
        </Row>
        <Row hidden={comentarioList?.length === 0 && !editing} gutter={10}>
          <Col span={24}>
            <List
              className="comment-list"
              header={<Title level={3}>Seguimiento</Title>}
              itemLayout="horizontal"
              dataSource={comentarioList}
              locale={{emptyText: "No hay seguimiento"}}
              renderItem={(item) => (
                <li>
                  <Comment
                    author={item.author}
                    avatar={item.avatar}
                    content={item.content}
                    datetime={item.datetime}
                  />
                </li>
              )}
            />
          </Col>

          <Col span={20}>
            <TextArea
              placeholder="Introduce aquí tu seguimiento"
              size="large"
              value={comentario}
              onChange={(e) => {
                setComentario(e.target.value);
              }}
              autoSize={{minRows: 3, maxRows: 20}}
            />
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              block
              size='large'
              onClick={() => comentar(0)}
            >
              Añadir seguimiento
            </Button>
          </Col>
        </Row>
        <Divider/>
        <Row gutter={10}>
          <Col span={24} style={{textAlign: "right"}}>
            <Button
              type="primary"
              title=""
              size='large'
              onClick={() => aprobarRecurso(model?.id, "APROBADO")}
              hidden={model?.calificacion === "APROBADO"}
            >
              <LikeOutlined/> Aprobar
            </Button>

            <Button
              hidden={model?.calificacion === "DESAPROBADO"}
              type="danger"
              title=""
              size='large'
              onClick={() => aprobarRecurso(model?.id, "DESAPROBADO")}
            >
              <DislikeOutlined/> Desaprobar
            </Button>

            <Button
              type="default"
              title=""
              size='large'
              onClick={() => history.push("/revision-obligaciones")}
            >
              <ArrowLeftOutlined/> volver
            </Button>
          </Col>
        </Row>
      </>
      }
    />
  );
};