import React from "react";
import { Modal, notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import httpService from "../services/httpService";
import { NON_DIGIT } from "../constants";
import {AgregarFaltantes} from "./AgregarFaltantes";
import {Link} from "react-router-dom";
const { REACT_APP_API_URL: baseURL } = process.env;

const capitalizeFirst = (string) => {
  const split = string.split("-")
  let palabraUnida = ""
  split.forEach((s) => {
    palabraUnida = palabraUnida + s.charAt(0).toUpperCase() + s.slice(1)
  })
  return palabraUnida;
};

const isEllipsis = (columns, key) => {
  const obtenerColumna = columns.find(column => column.key === key);
  return Boolean(obtenerColumna && obtenerColumna?.ellipsis)
}

const defaultText = (item, row, url, idModelo) =>
  <Link to={`${url}/detalle?id=${row[idModelo]}`} style={{color: 'black'}}>
    {item}
  </Link>

const onPreviewImage = async file => {
  let src = file.url;
  if (!src) {
    src = await new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  }
  const image = new Image();
  image.src = src;
  const imgWindow = window.open(src);
  imgWindow.document.write(image.outerHTML);
};

const propertyAccesor = (rootObj, accesor = "") => {
  if (!rootObj) return "";
  const properties = accesor.split(".");
  let tmp = rootObj;
  properties.forEach((prop) => (tmp = tmp[prop]));
  return tmp.toString();
};

const serialDateToJSDate = serial => {
  const step = new Date().getTimezoneOffset() <= 0 ? 25567 + 2 : 25567 + 1;
  const utc_days  = Math.floor(serial - step);
  const utc_value = utc_days * 86400;                                        
  const date_info = new Date(utc_value * 1000);
  const fractional_day = serial - Math.floor(serial) + 0.0000001;
  let total_seconds = Math.floor(86400 * fractional_day);
  const seconds = total_seconds % 60;
  total_seconds -= seconds;
  const hours = Math.floor(total_seconds / (60 * 60));
  const minutes = Math.floor(total_seconds / 60) % 60;
  return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
}

const validateName = (name) => {
  let re = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/
  return re.test(name);
};

const validateNumber = (number) => {
  const intValue = number.toString().replace(NON_DIGIT, "")
  return !isNaN(intValue);
};

const Trimestres = [
  {key:"trimestre1", label: "Trimestre 1"},
  {key:"trimestre2", label: "Trimestre 2"},
  {key:"trimestre3", label: "Trimestre 3"},
  {key:"trimestre4", label: "Trimestre 4"},
];

const eliminarRegistro = (nombre, id, url, alTerminar) => {

  if(!id) return;
  Modal.confirm({
    title: "Eliminar",
    content: `¿Está seguro de eliminar "${nombre}"?`,
    icon: <DeleteOutlined style={{ color: '#ff0000' }}/>,
    okText: 'Eliminar',
    okButtonProps: {
      type: 'danger',
    },
    cancelText: 'Cancelar',
    onOk: async () => {
      try {
        const res = await httpService.delete(url, { id: id});
        if (res && res.status === 200) {
          notification.success({
            message: 'Éxito',
            description: res?.mensaje
          });
          alTerminar && alTerminar();
        }
      else if ( res?.status === 400 ) {
        notification.error({
          message: "Atención",
          description: res?.mensaje,
        });
      }
      } catch (error) {
        console.log(error);
        notification.error({
          message: 'Error',
          description: error,
        });
        return 'error';
      }
    },
  });
};

const lastPathName = () => {
  const url = window.location.pathname;
  return {
    lastPath: url.split("/").pop(), // cambiar por pathname
    beforePath: url.split("/")[url.split("/").length -2]
  };
};

const openInNewTab = (ruta) => {
  window.open(`${baseURL}${ruta}`, '_blank', 'noopener,noreferrer');
}

const respuestas = (res) => {

  if (!res) return "Error en respuesta";

  if ((res?.status >= 400 && res?.status < 499) && res?.errores !== null) {
    const errores = Object.values(res?.errores)
    notification.error({
      message: 'Atención',
      description: errores.map((e, i) => <React.Fragment key={`${i}-error`}><span>- {e}</span><br/></React.Fragment>),
      placement: 'bottomRight'
    });
  } else if ((res?.status >= 400 && res?.status < 499) && res?.errores === null) {
    notification.error({
      message: 'Atención',
      description: 'Hubo un problema del lado del servidor.',
      placement: 'bottomRight'
    });
  } else if (res?.status >= 200 && res?.status < 399) {
    notification.success({
      message: '¡Éxito!',
      description: `${res?.mensaje}`,
      placement: 'bottomRight'
    });
  }
};

export { 
  capitalizeFirst,
  onPreviewImage,
  propertyAccesor,
  serialDateToJSDate,
  validateName,
  validateNumber,
  AgregarFaltantes,
  Trimestres,
  eliminarRegistro,
  defaultText,
  lastPathName,
  openInNewTab,
  respuestas,
  isEllipsis
};