import {React, useEffect, useState} from "react";
import {DefaultLayout} from "../../components/layouts";
import {useAuth, useModel, useModels, useQuery} from "../../hooks"
import {
  Button,
  Col,
  DatePicker,
  Form, Input, Modal, Row, Select, Upload
} from 'antd'
import {
  EditOutlined,
  FileSearchOutlined,
  PlusCircleOutlined,
  UploadOutlined
} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import StatusResponse from "../../services/statusResponse";
import {ViewLoading} from "../../components";
import locale from "antd/lib/date-picker/locale/es_ES";
import moment from 'moment';
import {anual, meses, semestres, trimestres} from "../../constants";
import {Respuesta} from "../../utilities/Respuesta";

const {Option, OptGroup} = Select;

export const TransparenciaDetalle = () => {
  const {user} = useAuth()
  const rol = user?.rol
  const [form] = Form.useForm()
  const history = useHistory()
  const q = useQuery()
  const id = q.get("id")
  const editing = !!id
  const [saveLoading, setSaveLoading] = useState(false)
  const [request, setRequest] = useState({})
  const [fileList, setFileList] = useState([])
  const [fracciones, setFracciones] = useState([])
  const [subirArchivo, setSubirArchivo] = useState(false);
  const [periodos, setPeriodos] = useState(trimestres);
  const breadcrumb = [
    {
      name: "Transparencia",
      to: "/documentacion/transparencia",
      icon: <FileSearchOutlined/>
    },
    {
      name: editing ? "Editar" : "Agregar",
      to: editing ? `/documentacion/transparencia/detalle?id=${id}` : `/documentacion/transparencia/nueva`,
      icon: editing ? <EditOutlined/> : <PlusCircleOutlined/>
    }
  ];

  const [
    articulos,
    articulosLoading
  ] = useModels(request)

  useEffect(() => {
    setRequest({
      name: 'articulo',
      ordenar: 'orden-asc',
      limite: -1,
      expand: 'fracciones',
      extraParams: {
        padres: 1
      }
    })
    return () => setRequest({})
  }, [])

  const {
    model,
    modelLoading,
  } = useModel({
    name: "transparencia",
    id: id,
  })

  useEffect(() => {
    if (editing && !modelLoading && !articulosLoading && model) {

      setFileList([{
        uid: '-1',
        name: model?.titulo || '',
        status: 'done',
        url: model?.ruta,
      }])

      const getData = async () => {
        const res = await StatusResponse.get("articulo?id=" + model?.idArticulo + "&expand=padre.fracciones")
        if (res && res.status === 200) {
          form.setFieldsValue({
            idArticulo: res?.resultado[0]?.idArticuloPadre,
            titulo: model?.titulo,
            descripcion: model?.descripcion,
            idFraccion: model?.idArticulo,
            fecha: moment(model?.fecha),
            ano: Number(model?.ano) > 0 ? moment().year(Number(model?.ano)) : moment(),
            trimestre: model?.trimestre,
          })
          setFracciones(res?.resultado[0]?.padre.fracciones)
        }
      }
      getData()
    }

  }, [articulosLoading, editing, form, model, modelLoading]);

  const onFinsih = async values => {
    let body = {
      ...values,
      fecha: moment(values?.fecha).format("YYYY-MM-DD HH:mm:ss"),
      ano: String(moment(values?.ano).year()),
    }

    let ruta = null
    const formData = new FormData();
    if (fileList?.length) {
      formData.append("archivo", fileList[0]?.originFileObj);
    }

    if (fileList[0]?.size > 85000000) {
      Modal.error(
        {
          title: 'archivo demasiado grande, el archivo no debe ser más grande a 80 Mb',
        }
      )
    }

    if (editing) {
      body.id = id
    }

    try {
      setSaveLoading(true)
      formData.append("anio", body.ano);
      formData.append("titulo", body.titulo);
      formData.append("fecha", body.fecha);
      formData.append("idArticulo", body.idArticulo);
      formData.append("idFraccion", body.idFraccion);
      formData.append("trimestre", body.trimestre);


      if ((body.trimestre === "4to Trimestre" && body.ano === "2022") || parseInt(body.ano) > 2022) {
        formData.append("tipo", "excelTransparencia")
      }

      if (subirArchivo) {
        const res = await StatusResponse.postFormData("transparencia-archivo", formData)
        if (res?.status === 400) {
          Modal.error({
            title: res?.mensaje
          })
          return;
        } else if (res?.status === 500) {
          Modal.error({
            title: <>Error interno, envíe un correo de soporte a tecnologias@transparenciasonora.org,
              con la siguiente información
            </>,
            content: <ul>
              <li>Captura de toda la pantalla con el error, si es posible video</li>
              <li>Archivo que intenta subir</li>
              <li>datos completos en los campos llenados</li>
            </ul>
          })
          return;
        } else if (res?.status === 200) {
          if (res?.detalle?.ruta) {
            ruta = res?.detalle?.ruta
            body.ruta = ruta
          }
        }
        console.log(res)
      }

      if ((body.trimestre === "4to Trimestre" && body.ano === "2022") || parseInt(body.ano) > 2022) {
        let nombreArchivo = fileList[0]?.name.split(".")
        body.extension = nombreArchivo[nombreArchivo.length - 1]
        body.mimeType = fileList[0]?.type;
      }

      if (body.extencion === body.archivo) {
        body.extension = model.extension
      }

      const res2 = await StatusResponse.post("transparencia", body)
      if (Respuesta(res2))
        history.push("/documentacion/transparencia")

    } catch (error) {
      console.log(error)
    } finally {
      setSaveLoading(false)
    }
  };

  if (modelLoading) return <ViewLoading/>

  console.log("5", user);

  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title={editing ? `Edtando Informe de Transparencia: ${model?.titulo}` : "Nuevo Informe de Transparencia"}
      children={
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinsih}
          initialValues={{
            fecha: moment(),
            ano: !editing && moment(),
          }}
        >
          <Row gutter={[16, 0]}>
            <Col sm={12} md={8}>
              <Form.Item
                style={{marginBottom: '0px'}}
                required
                name="titulo"
                label="Título"
                hasFeedback
                rules={[{required: true, message: 'Por favor ingrese el título de la tranparencia'}]}
              >
                <Input
                  maxLength={500}
                  placeholder="Escribe el título de la transparencia"
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={8}>
              <Form.Item
                style={{marginBottom: '0px'}}
                required
                name="idArticulo"
                label="Artículo"
                hasFeedback
              >
                <Select
                  optionFilterProp="children"
                  showSearch
                  filterOption={(input, option) => {
                    if (option.children) {
                      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    return false
                  }}
                  listItemHeight={10}
                  listHeight={250}
                  loading={articulosLoading}
                  placeholder="Selecciona un artículo"
                  onSelect={(_, opt) => {
                    setFracciones(opt?.item?.fracciones.sort((a, b) => a?.orden > b?.orden ? 1 : -1))
                  }}
                >
                  {
                    rol !== 'organismo-agua' && (
                      <OptGroup label="Leyes y Artículos">
                        {
                          articulos ?
                            articulos
                              .filter(f => !f?.ambito.includes("Informativo Portal"))
                              .filter(item => item?.ambito === user?.ente?.ambito)
                              .map((m, i) => <Option key={`leyes-articulos-${i}`} value={m?.id}
                                                     item={m}>{`${m.ambito} - ${m.titulo}`}</Option>)
                            : []
                        }
                      </OptGroup>
                    )
                  }
                  {
                    rol !== "sindicato" && rol !== "organismo-agua" && (
                      <OptGroup label="Información Portal">
                        {
                          articulos ?
                            articulos.filter(f => {
                              if (!f?.ambito.includes("Informativo Portal")) {
                                return false
                              }
                              if (rol === "municipio") {
                                return !f?.titulo.includes("Pleno")
                              }

                              return true
                            })
                              .map((m, i) => <Option key={`informacion-portal-${i}`} value={m?.id}
                                                     item={m}>{`${m.ambito} - ${m.titulo}`}</Option>)
                            : []
                        }
                      </OptGroup>
                    )
                  }
                  {
                    rol === "organismo-agua" && (
                      <OptGroup label="Información Portal">
                        {
                          articulos ?
                            articulos.filter(f => {
                              if (!f?.ambito.includes("Informativo Portal")) {
                                return false
                              }
                              return f?.titulo.includes("Organismo")
                            })
                              .map((m, i) => <Option key={`informativo-portal-${i}`} value={m?.id}
                                                     item={m}>{`${m.ambito} - ${m.titulo}`}</Option>)
                            : []
                        }
                      </OptGroup>
                    )
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col sm={12} md={8}>
              <Form.Item
                style={{marginBottom: '0px'}}
                required
                name="idFraccion"
                label="Fracción"
                hasFeedback
                rules={[{required: true, message: 'Por favor seleccione la fracción'}]}
              >
                <Select
                  optionFilterProp="children"
                  showSearch
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  listItemHeight={10}
                  listHeight={250}
                  options={fracciones ? fracciones.map((m, i) => ({
                    label: `${m.titulo}`,
                    value: m.id,
                    key: i,
                    item: m
                  })) : []}
                  onChange={(item, row) => {
                    if (fracciones.find(item => item.id === row.value)?.tiempoValidacion === "mensual")
                      setPeriodos(meses);
                    else
                      setPeriodos(trimestres);

                  }}
                  rules={[{required: true, message: 'Por favor seleccione una fracción'}]}
                  placeholder="Selecciona una fracción"
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={24}>
              <Form.Item
                style={{marginBottom: '0px'}}
                required
                name="descripcion"
                label="Descripción"
                hasFeedback
              >
                <Input.TextArea
                  placeholder="Escribe la descripción de la transparencia"
                  rows={4}
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={8}>
              <Form.Item
                style={{marginBottom: '0px'}}
                required
                name="trimestre"
                label="Tipo de Periodo"
                hasFeedback
                rules={[{required: true, message: 'Por favor seleccione el trimestre'}]}
              >
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  listItemHeight={10}
                  listHeight={250}
                  options={periodos}
                  rules={[{required: true, message: 'Por favor seleccione el tipo de periodo'}]}
                  placeholder="Selecciona el tipo de periodo"
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={8}>
              <Form.Item
                style={{marginBottom: '0px'}}
                required
                name="ano"
                label="Año"
                hasFeedback
                rules={[{required: true, message: 'Por favor seleccione el año'}]}
              >
                <DatePicker.YearPicker
                  style={{width: '100%'}}
                  placeholder="Selecciona el año"
                  locale={locale}
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={8}>
              <Form.Item
                label="Fecha"
                name="fecha"
                style={{marginBottom: '0px'}}
              >
                <DatePicker
                  style={{width: '100%'}}
                  showTime={{format: 'HH:mm'}}
                  format="DD/MM/YYYY HH:mm"
                  placeholder="Selecciona la fecha"
                  locale={locale}
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={8}>
              <Form.Item
                style={{marginBottom: '0px'}}
                required
                name="archivo"
                label="Archivo"
                hasFeedback
                rules={[{required: !editing ? true : false, message: 'Por favor seleccione un archivo'}]}
              >
                <Upload
                  style={{width: '100%'}}
                  name="file"
                  beforeUpload={() => false}
                  showUploadList={true}
                  fileList={fileList}
                  //accept=" .xls,.xlsx"
                  //disabled={camposVacios}
                  onChange={(info) => {
                    if (info.fileList.length > 1) {
                      info.fileList.shift()
                    }
                    setFileList(info.fileList)
                    setSubirArchivo(true)
                  }}
                >
                  <Button icon={<UploadOutlined/>}>
                    Subir archivo
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]} style={{marginTop: '10px'}}>
            <Col sm={24}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  loading={saveLoading}
                  type="primary"
                >
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      }
    />
  );
};