import React, {Children} from "react";
import {
  Modal,
  Image,
} from "antd";
import {
  FolderOpenOutlined,
  EditOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
  LockOutlined,
} from "@ant-design/icons";
import {DropdownOverlayButton} from "../../components";
import SimpleTableLayout from "../../components/layouts/SimpleTableLayout";
import {useHistory} from "react-router-dom";
import StatusResponse from "../../services/statusResponse";
import TablaComponent from "../../components/TablaComponent";
import {Respuestas} from "../../utilities/Respuesta";

const searchPlaceholder = "Buscar por título, descripción, etc.";
const emptyText = "No hay Sujetos Obligados registrados...";

export const Listado = ({endPoint, expand, url, orden, columnas, idModelo, viewTitle}) => {
  const history = useHistory();
  let tablaRef = React.useRef(null);

  const [searchLoading, setSearchLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");

  const breadcrumbItems = [
    {
      name: viewTitle,
      to: url,
      icon: <FolderOpenOutlined/>,
    },
  ];


  const buttonData = {
    text: "Agregar",
    icon: <PlusCircleOutlined/>,
    to: () => history.push(`${url}/detalle`),
  };


  const columns = [
    {
      title: "Acciones",
      key: "operation",
      ellipsis: true,
      width: 125,
      render: (_, row) => (
        <DropdownOverlayButton
          options={[
            {
              name: "Editar información",
              icon: <EditOutlined/>,
              onClick: () =>
                history.push(`${url}/detalle?id=${row[idModelo]}`),
              styleProps: {
                color: "#4495bb",
              },
            },
            {
              name: "Eliminar",
              icon: <LockOutlined/>,
              onClick: () => modalEliminar(row),
              styleProps: {
                color: "#f5222d",
              },
            },
          ]}
        />
      ),
    },
    ...columnas
  ];


  const onSearch = React.useCallback(async (v) => {
    setSearchLoading(true);
    setSearch(v);
    setTimeout(() => setSearchLoading(false), 1500);
  }, []);

  const modalEliminar = (valor) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{color: "red"}}/>,
      content: `¿Desea eliminar, "${valor?.titulo}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: {danger: true},
      closable: true,
      onOk: async () => {
        const res = await StatusResponse.delete(`${endPoint}/eliminar`, {
          id: valor[idModelo],
        });
        return new Promise((resolve, reject) => {
          try {
            if (Respuestas(res)) {
              tablaRef.current.refresh();
              resolve();
            }
            reject();
          } catch (error) {
            console.log(error);
            reject();
          }
        });
      },
    });
  };

  return (
    <SimpleTableLayout
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      title={viewTitle}
      searchPlaceholder={searchPlaceholder}
      searchLoading={searchLoading}
      onSearchClicked={onSearch}
      emptyText={emptyText}
    >
      <TablaComponent
        columns={columns}
        nameURL={endPoint}
        expand={expand}
        innerRef={tablaRef}
        expandable={{
          expandedRowRender: record => {
            console.log(record)
            return <Image src={record?.imagenDestacada[0]?.ruta}
                          alt={`Imagen principal ${record?.imagenDestacada[0]?.ruta}`} width="180px"/>
          },
        }}
        extraParams={{
          q: search,
          ordenar: orden
        }}
      />
    </SimpleTableLayout>
  );
};
