import React, { useMemo } from "react";
import {Button, Space, Tooltip,} from "antd";
import {AuditOutlined, EyeOutlined, FilePdfOutlined, MailOutlined} from "@ant-design/icons";
import SimpleTableLayout from "../../components/layouts/SimpleTableLayout";
import {useHistory} from "react-router-dom";
import moment from "moment"
import TablaComponent from "../../components/TablaComponent";

const viewTitle = "Recursos de Revisión";
const searchPlaceholder = "Buscar...";
const emptyText = "No hay recursos registrados...";

const breadcrumbItems = [
  {
    name: "Recursos de Revisión",
    to: "recurso-revision",
    icon: <AuditOutlined/>
  }
];

export const RecursoRevisionLista = () => {
  const history = useHistory();
  const [search, setSearch] = React.useState("")
  const [searchLoading, setSearchLoading] = React.useState(false)

  const sinDefinir = <i>---</i>

  const renderSimple = (item, record) => {
    return (<a onClick={() => history.push(`recurso-revision/detalle?id=${record?.id}`)}> {item} </a>);
  }

  const renderEstatus = (item, record) => {
    if (record.visto === null) {
      return <a onClick={() => history.push(`recurso-revision/detalle?id=${record?.id}`)}>
        <MailOutlined style={{fontSize: "20px"}}/> Nuevo
      </a>;
    } else {
      return <a onClick={() => history.push(`recurso-revision/detalle?id=${record?.id}`)}>
        {moment.utc(record.visto).local().format("DD/MM/YYYY HH:mm")}
      </a>;
    }
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
    },
    {
      title: "visto",
      dataIndex: "estatus",
      key: "estatus",
      render: renderEstatus
    },
    {
      title: "Fecha Creado",
      dataIndex: "creado",
      key: "creado",
      render: (prop, record) => record.creado ?
        <a onClick={() => history.push(`recurso-revision/detalle?id=${record?.id}`)}>
          {moment(record.creado).format("DD/MM/YYYY HH:mm")}
        </a>
        : sinDefinir
    },
    {
      title: "Solicitante",
      dataIndex: "solicitante",
      key: "solicitante",
      width: 200,
      ellipsis: true,
      render: renderSimple
    },
    {
      title: "Correo",
      dataIndex: "correo",
      key: "correo",
      width: 200,
      ellipsis: true,
      render: renderSimple
    },
    {
      title: "Fecha de respuesta o de solicitud",
      dataIndex: "fecha",
      key: "fecha",
      render: (prop, record) =>
        <a onClick={() => history.push(`recurso-revision/detalle?id=${record?.id}`)}>
          {prop ? moment(prop).format("DD/MM/YYYY") :
            <strong>No se introdujo fecha en la solicitud <br/> Revise la fecha de creado</strong>}
        </a>
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Space justify="space-between">
          <Tooltip title="Ver detalles">
            <Button
              type="primary"
              shape="circle"
              icon={<EyeOutlined/>}
              onClick={() => history.push(`recurso-revision/detalle?id=${record?.id}`)}
            />
          </Tooltip>
          <Tooltip title="Ver detalles">
            <Button
              type="danger"
              shape="circle"
              icon={<FilePdfOutlined/>}
              onClick={() => window.open(`https://intranet.api.transparenciasonora.org/reportes/recurso?id=${record.id}`, '_blank')}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const onSearch = React.useCallback(async (v) => {
    setSearchLoading(true);
    setSearch(v);
    let params = {};
    if (v.length > 0) params = {...params, q: v};
    params = {...params, pagina: 0};
    setTimeout(() => setSearchLoading(false), 1500);
  }, []);


  const extraParams = useMemo(() => {
    return {q: search}
  }, [search]);

  return (
    <SimpleTableLayout
      breadcrumbItems={breadcrumbItems}
      title={viewTitle}
      searchPlaceholder={searchPlaceholder}
      searchLoading={searchLoading}
      onSearchClicked={onSearch}
      emptyText={emptyText}

    >
      <TablaComponent
        nameURL={'recurso-revision'}
        columns={columns}
        order={'id-desc'}
        extraParams={extraParams}
      />
    </SimpleTableLayout>
  );
};
