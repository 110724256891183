import React from "react";
import { Button, Descriptions, Form, Table, Tooltip, Typography,  Modal } from "antd";
import { FolderOpenOutlined, EditOutlined, EyeOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { DropdownOverlayButton } from "../../components";
import SimpleTableLayout from "../../components/layouts/SimpleTableLayout";
import { useModels } from "../../hooks"
import { useHistory } from "react-router-dom";
import moment from "moment"
import { Search } from "./form";

const viewTitle = "Documentos";
const searchPlaceholder = "Buscar por título, descripción, etc.";
const emptyText = "No hay documentos registrados...";

const breadcrumbItems = [
  { 
    name: "Documentos",
    to: "documentos",
    icon: <FolderOpenOutlined /> 
  }
];

const { Paragraph } = Typography;

export const DocumentoLista = () => {
  const [searchForm] = Form.useForm();
  const history = useHistory();
  const [pageSize, setPageSize] = React.useState(10)
  const [searchLoading, setSearchLoading] = React.useState(false)
  const [request, setRequest] = React.useState([])
  const [row, setRow] = React.useState(null)
  const [modalVisible, setModalVisible] = React.useState(false)

  const requestParams = React.useMemo(() => ({
    name: 'documento-publico',
    ordenar: 'id-desc',
    limite: pageSize,
    expand: 'categoria'
  }),[pageSize])

  const buttonData = {
    text: "Nuevo Documento",
    icon: <PlusCircleOutlined />,
    to: () => history.push("/documentacion/documentos/nuevo")
  }

  const [
    models,
    modelsLoading,
    // eslint-disable-next-line no-unused-vars
    modelsError,
    modelsPage,
    refreshModels
  ] = useModels(request)

  React.useEffect(() => { 
    setRequest(requestParams)
    return () => setRequest({})
  },[requestParams])

  const sinDefinir = <i>-Sin definir-</i>

  const renderSimple = (prop) => prop || sinDefinir;

  const columns = [
    {
      title: "Acciones",
      key: "operation",
      render: (_, row) => (
        <DropdownOverlayButton
          options={[
            {
              name: "Ver detalle",
              icon: <EyeOutlined />,
              onClick: () => modalInfo(row)
            },
            {
              name: "Editar información",
              icon: <EditOutlined />,
              onClick: () => history.push(`documentos/detalle?id=${row?.id}`),
              styleProps: {
                color: "#4495bb"
              }
            },
          ]}
        />
      ),
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Categoría",
      dataIndex: "categoria",
      key: "categoria",
      render: prop => prop?.titulo || sinDefinir
    },
    {
      title: "Título",
      dataIndex: "titulo",
      key: "titulo",
      render: renderSimple
    },
    {
      title: "Ruta",
      dataIndex: "ruta",
      key: "ruta",
      render: (_, row) => row ?  (
        <Tooltip title="Copiar">
          <Paragraph copyable={{ text: `https://intranet.api.transparenciasonora.org/publico/descarga/documento?id=${row?.id}&${row?.titulo}` }}>
            <Button
              type="link"
              onClick={() => navigator.clipboard.writeText(`https://intranet.api.transparenciasonora.org/publico/descarga/documento?id=${row?.id}&${row?.titulo}`)}
            >
              {`https://intranet.api.transparenciasonora.org/publico/descarga/documento?id=${row?.id}&${row?.titulo}`}
            </Button>
          </Paragraph>
        </Tooltip>
      ) : sinDefinir
    },
    {
      title: "Fecha",
      dataIndex: "fechaSubida",
      key: "fechaSubida",
      render: prop => prop ? moment.utc(prop).local().format("DD/MM/YYYY HH:ss") : sinDefinir
    },
    
  ];

  const ContentModal = ({row}) => {
    return (
      <div>
        <Descriptions
          bordered
          column={1}
          size="small"
          title="Información del documento"
        >
          <Descriptions.Item label="ID" labelStyle={{ fontWeight: "bold" }}> { row?.id || sinDefinir } </Descriptions.Item>
          <Descriptions.Item label="Categoría"> { row?.categoria?.titulo || sinDefinir } </Descriptions.Item>
          <Descriptions.Item label="Título" labelStyle={{ fontWeight: "bold" }}> { row?.titulo || sinDefinir } </Descriptions.Item>
          <Descriptions.Item label="Ruta" labelStyle={{ fontWeight: "bold" }}>
            {
              row?.ruta ? (
                <Tooltip title="Copiar">
                <Paragraph copyable={{ text: row?.ruta }}>
                  <Button
                    type="link"
                    onClick={() => navigator.clipboard.writeText(row?.ruta)}
                  >
                    { row?.ruta }
                  </Button>
                </Paragraph>
              </Tooltip>
              ) : sinDefinir 
            } 
            </Descriptions.Item>
          <Descriptions.Item label="Fecha de subida"> { row?.fechaSubida ? moment(row?.fechaSubida).format("DD/MM/YYYY HH:ss") : sinDefinir } </Descriptions.Item>
        </Descriptions>
      </div>
    )
  }

  const modalInfo = async v => {
    setRow(v)
    setModalVisible(true)
  };

  const onSearch = React.useCallback(async (v) => {
    setSearchLoading(true);
    const { rango, buscar } = searchForm.getFieldsValue(true);
    let params = {};
    if (buscar?.length > 0)
      params = {
        ...params,
        q: buscar
      };
    if (rango){
      const [inicio, fin] = rango;
      if(inicio && fin) 
        params = {
          ...params,
          inicio: inicio.format("YYYY-MM-DD"),
          fin: fin.format("YYYY-MM-DD")
        }
    }
    params = { ...params, pagina: 0 };
    await refreshModels(true, params);
    setSearchLoading(false)
  },[refreshModels, searchForm]);


  const onChangeCallback = React.useCallback(
    async ( page, size ) => {
      setPageSize(size);
      await refreshModels(true, { 
        ordenar: 'id-asc',
        pagina: page,
        limite: size,
      })
  }, [refreshModels]);

  const getPageSizeOptions  = React.useMemo(() => {
    const options = [10, 20, 50, 100, 200, 500].filter(val => val <= modelsPage?.total);
    if (options[options.length - 1] !== modelsPage?.total) {
      options.push(modelsPage?.total);
    }
    return options
  },[modelsPage]);

  const config = React.useMemo(() => {
    if( modelsPage ) {
      let size = pageSize;
      return {        
        total: modelsPage.total,
        pageSize: modelsPage.limite,
        onShowSizeChange: ( _, newSize)  => ( size = newSize ),
        onChange: async (v) => await onChangeCallback(v, size),
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} documentos.`,
        locale: { items_per_page: '/ página' },
        pageSizeOptions: getPageSizeOptions,
        showSizeChanger: true
      }
    }
    return null;
  }, [onChangeCallback, pageSize, modelsPage, getPageSizeOptions]);

  const onClean = () => {
    searchForm.resetFields();
  }

  return (
    <SimpleTableLayout
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      title={viewTitle}
      searchPlaceholder={searchPlaceholder}
      searchLoading={searchLoading}
      onSearchClicked={onSearch}
      emptyText={emptyText}
      withSearchButton={false}
      children={
        <>
          <Search
            disabledButtons={modelsLoading}
            form={searchForm}
            loading={searchLoading}
            onClean={onClean}
            onSearch={onSearch}
          />
          <Table
            columns={columns}
            dataSource={models}
            loading={{
              spinning: modelsLoading,
              tip: 'Cargando información...',
            }}
            locale={{ emptyText }}
            pagination={config}
            rowKey="id"
            scroll={{ x: 'max-content' }}
          />
          <Modal
            onCancel={() => setModalVisible(false)}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ type: 'primary' }}
            cancelText="Cerrar"
            visible={modalVisible}
            width={'50%'}
          >
            <ContentModal row={row}/>
          </Modal>
        </>
      }
    />
  );
};