import React, { useMemo } from "react";
import { Button, Space, Tooltip, } from "antd";
import {AuditOutlined, EyeOutlined, FilePdfOutlined, MailOutlined} from "@ant-design/icons";
import SimpleTableLayout from "../../components/layouts/SimpleTableLayout";
import { useHistory } from "react-router-dom";
import moment from "moment"
import TablaComponent from "../../components/TablaComponent";

const viewTitle = "Solicitudes de Información";
const searchPlaceholder = "Buscar...";
const emptyText = "No hay Solicitudes registrados...";

const breadcrumbItems = [
  {
    name: "Solicitud de Información",
    to: "solicitud-informacion",
    icon: <AuditOutlined />
  }
];

export const SolicitudInformacionLista = () => {
  const history = useHistory();
  const [search, setSearch] = React.useState("")
  const [searchLoading, setSearchLoading] = React.useState(false)

  const sinDefinir = <i>-Sin definir-</i>

  const renderSimple = (item, record) => {
    return(<a onClick={() => history.push(`solicitud-informacion/detalle?id=${record?.id}`)}> {item} </a>);
  }

  const renderEstatus = (item, record) => {
    if (record.visto === null){
      return <a onClick={() => history.push(`solicitud-informacion/detalle?id=${record?.id}`)}>
        <MailOutlined style={{fontSize:"20px"}}/> Nuevo
      </a>;
    }
    else{
      return <a onClick={() => history.push(`solicitud-informacion/detalle?id=${record?.id}`)}>
        {moment.utc(record.visto).local().format("DD/MM/YYYY HH:mm")}
      </a>;
    }
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 100,
    },
    {
      title: "visto",
      dataIndex: "estatus",
      key: "estatus",
      width: 100,
      render: renderEstatus
    },
    {
      title: "Fecha",
      dataIndex: "fecha",
      key: "fecha",
      width: 100,
      render: (prop, record) => record.creado ?
        <a onClick={() => history.push(`solicitud-informacion/detalle?id=${record?.id}`)}>
          {moment(record.creado).format("DD/MM/YYYY HH:mm")}
        </a>
        : sinDefinir    },
    {
      title: "Sujeto Obligado",
      dataIndex: "sujetoObligado",
      key: "sujetoObligado",
      width: 200,
      ellipsis: true,
      render: renderSimple
    },
    {
      title: "Nombre del solicitante",
      dataIndex: "nombreSolicitante",
      key: "nombreSolicitante",
      width: 200,
      render: renderSimple
    },
    {
      title: "Acciones",
      key: "operation",
      width: 100,
      render: (_, record) => (
        <Space justify="space-between">
          <Tooltip title="Ver detalles">
            <Button
              type="primary"
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() => history.push(`solicitud-informacion/detalle?id=${record?.id}`)}
            />
          </Tooltip>
          <Tooltip title="Descargar PDF">
            <Button
              type="danger"
              shape="circle"
              icon={<FilePdfOutlined />}
              onClick={() =>  window.open( `https://intranet.api.transparenciasonora.org/reportes/solicitud?id=${record.id}`, '_blank'  )}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const onSearch = React.useCallback(async (v) => {
    setSearchLoading(true);
    setSearch(v);
    let params = {};
    if (v.length > 0) params = { ...params, q: v };
    params = { ...params, pagina: 0 };
    setTimeout(() => setSearchLoading(false), 1500);
  },[]);

  const extraParams = useMemo(() => {
    return {q: search}
  }, [search]);

  return (
    <SimpleTableLayout
      breadcrumbItems={breadcrumbItems}
      title={viewTitle}
      searchPlaceholder={searchPlaceholder}
      searchLoading={searchLoading}
      onSearchClicked={onSearch}
      emptyText={emptyText}
    >
      <TablaComponent
        nameURL={'solicitud-informacion'}
        columns={columns}
        order={'id-desc'}
        extraParams={extraParams}
      />
    </SimpleTableLayout>
  );
};
