import { React, useEffect, useState, useCallback } from "react";
import { DefaultLayout } from "../../components/layouts";
import { useModel, useModels, useQuery } from "../../hooks";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select as AntSelect,
  Upload,
  message,
} from "antd";
import {
  EditOutlined,
  FolderOpenOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import StatusResponse from "../../services/statusResponse";
import { ViewLoading, Select as SelectComponent } from "../../components";
import moment from "moment";
import { Select } from "../../components";

export const SujetosObligadosDetalle = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const q = useQuery();
  const id = q.get("id");
  const editing = !!id;
  const [saveLoading, setSaveLoading] = useState(false);
  const [entesAmbito, setEntesAmbito] = useState([]);
  const [ambito, setAmbito] = useState([]);
  const [limite, setLimite] = useState(10);
  const breadcrumb = [
    {
      name: "Sujetos Obligados",
      to: "/sujetos-obligados",
      icon: <FolderOpenOutlined />,
    },
    {
      name: editing ? "Editar" : "Agregar",
      to: editing
        ? `/sujetos-obligados/detalle?id=${id}`
        : `/sujetos-obligados/nueva`,
      icon: editing ? <EditOutlined /> : <PlusCircleOutlined />,
    },
  ];
  const { model, modelLoading } = useModel({
    name: "sujeto-obligado",
    id: id,
  });

  const [entes, entesLoading, , , entesRefresh] = useModels({
    name: "ente",
    ordenar: "titulo-asc",
    limite: -1,
  });

  const [ambitos, ambitosLoading] = useModels({
    name: "ambito",
    ordenar: "titulo-asc",
    limite: -1,
  });

  const setFormData = useCallback(
    (data) => {
      const { idEnte, idAmbito, nombre, direccion, correo, telefono } = data;

      form.setFieldsValue({
        idEnte,
        idAmbito,
        nombre,
        direccion,
        correo,
        telefono,
      });
    },
    [form]
  );

  useEffect(() => {
    let mounted = true;
    if (mounted && editing && model) {
      setFormData(model);
    }
    setEntesAmbito(entes);
    return () => (mounted = false);
  }, [editing, model, setFormData]);

  useEffect(() => {
    if (ambito !== undefined && ambito.length > 0)
      setEntesAmbito(
        entes.filter(
          (item) =>
            item.ambito === (ambito === "Ayuntamientos" ? "Municipal" : ambito)
        )
      );
    else {
      setEntesAmbito(entes);
    }
  }, [entes, ambito]);

  const onFinsih = async (values) => {
    let _body = { ...values };

    if (editing) {
      _body.id = id;
    }

    _body.idEnte = values?.idEnte?.value;
    try {
      setSaveLoading(true);
      const res2 = await StatusResponse.post("sujeto-obligado/guardar", _body);
      if (res2?.status === 400 && res2?.errores !== null) {
        const newArray = Object.values(res2?.errores);
        Modal.error({
          title: res2?.mensaje,
          content: (
            <div>
              {newArray.map((m, i) => (
                <span key={i + 1}>
                  - {m}
                  <br />
                </span>
              ))}
            </div>
          ),
        });
      } else if (res2?.status === 400 && res2?.errores === null) {
        Modal.error({
          title: res2?.mensaje,
        });
      } else if (res2?.status === 200) {
        let secondsToGo = 2;
        const modal = Modal.success({
          title: res2?.mensaje,
          cancelButtonProps: { style: { display: "none" } },
          okButtonProps: { style: { display: "none" } },
        });
        const timer = setInterval(() => {
          secondsToGo -= 1;
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          modal.destroy();
        }, secondsToGo * 1000);
        history.push("/sujetos-obligados");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSaveLoading(false);
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    message.warning("Error al guardar: datos incompletos.");
    console.log(values, errorFields, outOfDate);
  };

  const buscarEnte = async (value) => {
    if (value?.length > 0) {
      await entesRefresh(true, {
        q: value,
      });
    }
  };

  if (modelLoading) return <ViewLoading />;

  console.log(entes);

  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title={
        editing
          ? `Editando Sujeto Obligado: ${model?.nombre}`
          : "Nuevo Sujeto Obligado"
      }
      children={
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinsih}
          onFinishFailed={onFinishFailed}
          initialValues={{
            fechaSubida: moment(),
          }}
        >
          <Row gutter={[16, 0]}>
            <Col sm={12} md={8}>
              <Form.Item
                style={{ marginBottom: "0px" }}
                required
                name="idAmbito"
                label="Tipo"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Por favor seleccione una Ambito",
                  },
                ]}
              >
                <AntSelect
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  listItemHeight={10}
                  listHeight={250}
                  loading={ambitosLoading}
                  options={
                    ambitos
                      ? ambitos.map((m, i) => ({
                          label: `${m.titulo}`,
                          value: m.id,
                          key: i,
                          item: m,
                        }))
                      : []
                  }
                  onChange={(value) => {
                    setAmbito(value);
                  }}
                  placeholder="Selecciona una Ambitos"
                />
              </Form.Item>
            </Col>

            <Col sm={12} md={8}>
              <Form.Item
                style={{ marginBottom: "0px" }}
                required
                name="idEnte"
                label="Ente"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Por favor seleccione una Ente",
                  },
                ]}
              >
                <Select
                  modelsParams={{
                    name: "ente",
                    ordenar: "titulo-asc",
                    limite: -1,
                  }}
                  showSearch
                  valueProp={"id"}
                  autoComplete={"off"}
                  optionFilterProp="children"
                  placeholder="Selecciona un Ente"
                  allowClear
                  labelInValue
                  render={(_, row) => `${row?.titulo}`}
                />
              </Form.Item>
            </Col>

            <Col sm={12} md={8}>
              <Form.Item
                style={{ marginBottom: "0px" }}
                required
                name="nombre"
                label="Enlace"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese el El nombre de la persona",
                  },
                ]}
              >
                <Input maxLength={255} placeholder="Escribe el Enlace" />
              </Form.Item>
            </Col>

            <Col sm={12} md={8}>
              <Form.Item
                style={{ marginBottom: "0px" }}
                required
                name="direccion"
                label="Dirección"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message:
                      "Por favor ingrese direccion de el Sujeto Obligado",
                  },
                ]}
              >
                <Input maxLength={255} placeholder="Escribe la Dirección" />
              </Form.Item>
            </Col>

            <Col sm={12} md={8}>
              <Form.Item
                style={{ marginBottom: "0px" }}
                required
                name="correo"
                label="Correo Electónico"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese el correo del Sujeto Obligado",
                  },
                ]}
              >
                <Input
                  maxLength={255}
                  placeholder="Escribe el Correo Electónico"
                />
              </Form.Item>
            </Col>

            <Col sm={12} md={8}>
              <Form.Item
                style={{ marginBottom: "0px" }}
                required
                name="telefono"
                label="Teléfono"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message:
                      "Por favor ingrese el Teléfono del Sujeto Obligado",
                  },
                ]}
              >
                <Input
                  maxLength={255}
                  placeholder="Escribe el título del documento"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 0]} style={{ marginTop: "10px" }}>
            <Col sm={24}>
              <Form.Item>
                <Button htmlType="submit" loading={saveLoading} type="primary">
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      }
    />
  );
};
