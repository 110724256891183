import {React} from "react";
import {DefaultLayout} from "../../components/layouts";
import {useModel, useQuery} from "../../hooks"
import {Button, Descriptions, Upload} from 'antd'
import {
  AuditOutlined,
  EyeOutlined,
  FilePdfOutlined
} from "@ant-design/icons";
import {ViewLoading} from "../../components";
import moment from "moment";

export const SolicitudInformacionDetalle = () => {
  const q = useQuery()
  const id = q.get("id")
  const editing = !!id
  const breadcrumb = [
    {
      name: "Solicitud de Informacion",
      to: "/mensajeria/solicitud-informacion",
      icon: <AuditOutlined/>
    },
    {
      name: editing && "Detalle",
      to: editing && `/mensajeria/solicitud-informacion/detalle?id=${id}`,
      icon: editing && <EyeOutlined/>
    }
  ];
  const {
    model,
    modelLoading,
  } = useModel({
    name: "solicitud-informacion",
    id: id,
  })

  if (modelLoading) return <ViewLoading/>

  console.log(model)

  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title={editing && `${model?.sujetoObligado}`}
      children={<>
        <Button
          type="danger"
          title=""
          onClick={() => window.open(`https://intranet.api.transparenciasonora.org/reportes/solicitud?id=${model.id}`, '_blank')}
        >
          <FilePdfOutlined/> Descargar PDF
        </Button>
        <Descriptions
          bordered
          column={{xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1}}
          size="small"
        >

          <Descriptions.Item label="Fecha" span={5}>{moment(model?.fecha).format("DD/MM/YYYY")}</Descriptions.Item>
          <Descriptions.Item label="Sujeto Obligado" span={5}>{model?.sujetoObligado}</Descriptions.Item>
          <Descriptions.Item label="Nombre Solicitante" span={5}>{model?.nombreSolicitante}</Descriptions.Item>
          <Descriptions.Item label="Correo Electrónico" span={5}>{model?.correoElectronico}</Descriptions.Item>
          <Descriptions.Item label="Télefono:" span={5}>{model?.telefono}</Descriptions.Item>
          <Descriptions.Item label="Información que solicita:" span={5}>{model?.informacion}</Descriptions.Item>
        </Descriptions></>
      }
    />
  );
};