import React, { useMemo } from "react";
import { Button, Space, Tooltip } from "antd";
import {AuditOutlined, EyeOutlined, FilePdfOutlined, MailOutlined} from "@ant-design/icons";
import SimpleTableLayout from "../../components/layouts/SimpleTableLayout";
import { useHistory } from "react-router-dom";
import moment from "moment"
import TablaComponent from "../../components/TablaComponent";

const viewTitle = "Denuncias";
const searchPlaceholder = "Buscar...";
const emptyText = "No hay denuncias registrados...";

const breadcrumbItems = [
  {
    name: "Denuncia",
    to: "denuncia",
    icon: <AuditOutlined />
  }
];

export const DenunciaLista = () => {
  const history = useHistory();
  const [search, setSearch] = React.useState("")
  const [searchLoading, setSearchLoading] = React.useState(false)

  const sinDefinir = <i>-Sin definir-</i>

  const renderSimple = (item, record) => {
    return(<a onClick={() => history.push(`denuncia/detalle?id=${record?.id}`)}> {item} </a>);
  }

  const renderEstatus = (item, record) => {
    if (record.visto === null){
      return <a onClick={() => history.push(`denuncia/detalle?id=${record?.id}`)}>
        <MailOutlined style={{fontSize:"20px"}}/> Nuevo
      </a>;
    }
    else{
      return <a onClick={() => history.push(`denuncia/detalle?id=${record?.id}`)}>
        {moment.utc(record.visto).local().format("DD/MM/YYYY HH:mm")}
      </a>;
    }
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      width: 100,
    },
    {
      title: "visto",
      dataIndex: "estatus",
      key: "estatus",
      width: 100,
      render: renderEstatus
    },
    {
      title: "Creado",
      dataIndex: "creado",
      key: "creado",
      fixed: "left",
      width: 100,
      render: (prop, record) => record.creado ?
        <a onClick={() => history.push(`denuncia/detalle?id=${record?.id}`)}>
          {moment(record.creado).format("DD/MM/YYYY HH:mm")}
        </a>
        : sinDefinir    },
    {
      title: "Correo denunciante",
      dataIndex: "correoDenunciante",
      key: "correoDenunciante",
      fixed: "left",
      width: 200,
      render: renderSimple
    },
    {
      title: "Nombre del denunciante",
      dataIndex: "nombreDenunciante",
      key: "nombreDenunciante",
      fixed: "left",
      width: 200,
      render: renderSimple
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Space justify="space-between">
          <Tooltip title="Ver detalles">
            <Button
              type="primary"
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() => history.push(`denuncia/detalle?id=${record?.id}`)}
            />
          </Tooltip>
          <Tooltip title="Descargar PDF">
            <Button
              type="danger"
              shape="circle"
              icon={<FilePdfOutlined />}
              onClick={() => window.open(`https://intranet.api.transparenciasonora.org/reportes/denuncia?id=${record?.id}`, '_blank')}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const onSearch = React.useCallback(async (v) => {
    setSearchLoading(true);
    setSearch(v);
    let params = {};
    if (v.length > 0) params = { ...params, q: v };
    params = { ...params, pagina: 0 };
    setTimeout(() => setSearchLoading(false), 1500);
  },[]);


  const extraParams = useMemo(() => {
    return {q: search}
  }, [search]);

  return (
    <SimpleTableLayout
      breadcrumbItems={breadcrumbItems}
      title={viewTitle}
      searchPlaceholder={searchPlaceholder}
      searchLoading={searchLoading}
      onSearchClicked={onSearch}
      emptyText={emptyText}
    >
      <TablaComponent
        nameURL={'denuncia'}
        columns={columns}
        order={'id-desc'}
        extraParams={extraParams}
      />
    </SimpleTableLayout>
  );
};
