import moment from "moment";
import {Button, Col, Form, Input, Modal, Row, Select, Upload} from "antd";
import TextArea from "antd/es/input/TextArea";
import {UploadOutlined} from "@ant-design/icons";
import {React, useCallback, useEffect, useState} from "react";
import StatusResponse from "../../../services/statusResponse";
import {useHistory} from "react-router-dom";
import {Respuestas} from "../../../utilities/Respuesta";
import {Select as SelectComponent} from "../../../components";

export const Formulario = ({id, model, urlBase, titulo}) => {
  const [form] = Form.useForm()
  const history = useHistory()
  const [saveLoading, setSaveLoading] = useState(false)
  const [subirArchivo, setSubirArchivo] = useState(false)
  const [subCategorias, setSubCategorias] = useState([])

  const setFormData = useCallback((data) => {
    const {
      idCategoria,
      idSubCategoria,
      nombre,
      descripcion,
      ruta,
      creado,
    } = data

    setSubCategorias(model?.categoria?.subCategorias);

    form.setFieldsValue({
      idCategoria,
      idSubCategoria,
      nombre,
      descripcion,
      ruta,
      fechaSubida: moment(creado),
      archivo: [
        {
          uid: ruta,
          name: ruta,
          status: "done",
          url: ruta,
        },
      ]
    })
  }, [form])

  const onFinsih = async values => {
    const {archivo} = values
    let _body = {...values}
    let ruta = null
    if (id) {
      _body.id = id
    }
    const formData = new FormData()
    if (archivo?.length) {
      formData.append("archivo", archivo[0]?.originFileObj)
    }

    try {
      setSaveLoading(true)

      if (subirArchivo) {
        const res = await StatusResponse.postFormData("transparencia-archivo", formData)
        if (res?.status === 400 && res?.errores === null) {
          Modal.error({
            title: res?.mensaje
          })
        } else if (res?.status === 200) {
          if (res?.detalle?.ruta) {
            ruta = res?.detalle?.ruta
            _body.ruta = ruta
          }
        }
      }

      let nombreArchivo = archivo[0]?.name.split(".")
      _body.extension = nombreArchivo[nombreArchivo.length - 1]
      _body.mimeType = archivo[0]?.type;

      const res2 = await StatusResponse.post("documento-portal/guardar", _body)

      if (Respuestas(res2))
        history.push(urlBase);

    } catch (error) {
      console.log(error)
    } finally {
      setSaveLoading(false)
    }
  };

  useEffect(() => {
    let mounted = true
    if (mounted && model) {
      setFormData(model)
    }
    return () => mounted = false
  }, [model, setFormData]);

  return <Form
    form={form}
    layout="vertical"
    onFinish={onFinsih}
  >
    <Row gutter={[16, 0]}>
      <Col sm={12} md={8}>
        <Form.Item
          style={{marginBottom: '0px'}}
          required
          name="nombre"
          label="Título"
          hasFeedback
          rules={[{required: true, message: 'Por favor ingrese el título del documento'}]}
        >
          <Input
            maxLength={255}
            placeholder="Escribe el título del documento"
          />
        </Form.Item>
      </Col>

      <Col sm={12} md={8}>
        <Form.Item
          style={{marginBottom: '0px'}}
          required
          name="idCategoria"
          label="Categoría"
          hasFeedback
        >
          <SelectComponent
            valueProp={'id'}
            labelProp={'nombre'}
            onChange={(val, item) => {
              setSubCategorias(item?.subCategorias)
              form.setFieldsValue({idSubCategoria: null}) //setFieldValue("idSubCategoria", null)
            }}
            modelsParams={{
              name: 'documento-portal-categoria',
              ordenar: 'nombre',
              expand: 'subCategorias'
            }}
          />
        </Form.Item>
      </Col>

      <Col sm={12} md={8}>
        <Form.Item
          style={{marginBottom: '0px'}}
          required
          name="idSubCategoria"
          label="Sub Categoría"
          hasFeedback
        >
          <Select
            options={subCategorias?.map(item => ({key: item.id, label: item.nombre, value: item.id}))}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label="Descripción"
          name="descripcion"
          style={{marginBottom: '0px'}}
        >
          <TextArea
            placeholder="Escribe la descripción de la transparencia"
            rows={4}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          style={{marginBottom: '0px'}}
          required
          name="archivo"
          label="Archivo"
          hasFeedback
          valuePropName="fileList"
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e
            }
            return e && e.fileList
          }}
          onChange={(_) => setSubirArchivo(true)}
        >
          <Upload
            style={{width: '100%'}}
            beforeUpload={() => false}
            showUploadList={true}
            onChange={(info) => {
              if (info.fileList.length > 1) {
                info.fileList.shift()
              }
              form.setFieldsValue({
                archivo: info.fileList
              })
            }}
          >
            <Button icon={<UploadOutlined/>}>
              Subir archivo
            </Button>
          </Upload>
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={[16, 0]} style={{marginTop: '10px'}}>
      <Col sm={24}>
        <Form.Item>
          <Button
            htmlType="submit"
            loading={saveLoading}
            type="primary"
          >
            Guardar
          </Button>
        </Form.Item>
      </Col>
    </Row>
  </Form>
}